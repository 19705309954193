import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../../_admin/assets/media/close.png';
import { useEffect, useState } from 'react';
import {
  buyer,
  customerService,
  reports,
  seller,
} from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import APICallService from '../../../api/apiCallService';
import { OrderCash, OrderTigoPesa } from '../../../utils/constants';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import { CustomReportSelect } from '../../custom/Select/CustomReportSelect';
import { error } from '../../../Global/toast';
import { reportJson } from '../../../api/apiJSON/reports';
import clsx from 'clsx';
const DashboardReport = (props: any) => {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [districtData, setDistrictData] = useState([]);
  const [totalDistrict, setTotalDistrict] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDistricts();
      setFetchLoader(false);
    })();
  }, []);
  const fetchDistricts = async () => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',props.moduleName);
    let response = await apiService.callAPI();
    setTotalDistrict(response.total);
    setDistrictData(response.records);
  };
  const handleDistrict = async (event: any) => {
    setSelectedDistrict(event);
    setValidation(false);
    if (!event.length) {
      setValidation(true);
    }
  };
  const handleSubmit = async () => {
    if (selectedDistrict.length && startDate && endDate) {
      if (Method.dayDifference(startDate, endDate) < 0) {
        return error('Please select a valid date range');
      }
      let district: any = [];
      selectedDistrict.map((val: any) => {
        district.push(val.id);
      });
      // let params: any = {
      //   districts: district,
      //   utcOffset: new Date().getTimezoneOffset(),
      // };
      // if (startDate && endDate) {
      //   params = {
      //     ...params,
      //     fromDate: Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
      //     toDate: Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
      //   };
      // }
      setLoading(true);
      let apiService = new APICallService(
        reports.dashboardReport,
        reportJson.getReportWithKey(
          {
            data: district,
            utcOffset: new Date().getTimezoneOffset(),
            fromDate: startDate,
            toDate: endDate,
          },
          'districts'
        ),
        undefined,
        'blob',
        '','',props.moduleName
      );
      let response = await apiService.callAPI();
      if (response) {
        const pdfBlob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download =
          'dashboard_report' +
          Method.convertDateToDDMMYYYY(startDate) +
          '_' +
          Method.convertDateToDDMMYYYY(endDate) +
          '.pdf';
        downloadLink.click();
        props.onHide();
      } else {
        error('No payment was done on selected date');
        props.onHide();
      }
    } else if (selectedDistrict.length === 0) {
      setValidation(true);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-690px"
        className="border-r10px"
        contentClassName="p-5"
        backdrop={loading ? 'static' : true}
      >
        <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
          {!loading ? (
            <div className="symbol symbol-md-40px symbol-35px close-inner-top">
              <img
                width={40}
                height={40}
                src={CrossSvg}
                alt=""
                onClick={props.onHide}
              />
            </div>
          ) : (
            <></>
          )}
          <Modal.Title className="fs-30 fw-bolder mw-lg-350px">
            Download report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-5">
          <Row className="align-items-center g-3">
            <Col md={12}>
              <div className="d-flex align-items-center mb-2">
                <div className="fs-18 fw-500">Download Report for </div>
              </div>
              <CustomReportSelect
                border={validation ? '#e55451' : '#e0e0df'}
                backgroundColor="#ffff"
                minHeight="60px"
                isLoading={fetchLoader}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={
                  districtData && districtData.length
                    ? districtData.map((val: any) => {
                        return {
                          label: (
                            <>
                              <span className="fs-16 fw-600 text-black mb-0">
                                {val.name}
                              </span>
                            </>
                          ),
                          value: val._id,
                          id: val._id,
                          title: val.name,
                        };
                      })
                    : []
                }
                text={'sellers selected'}
                hideSelectedOptions={false}
                onChange={(event: any) => {
                  handleDistrict(event);
                }}
                // isClearable={selectedSeller ? true : false}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row className="align-items-center g-3 mt-4">
            <Col md={12}>
              <div className="d-flex align-items-center mb-2">
                <div className="fs-18 fw-500">Select date </div>
              </div>
            </Col>
            <Col md={5}>
              <CustomDatePicker
                className={clsx(
                  'form-control bg-white min-h-60px fs-16 fw-bold ',
                  !startDate ? 'border-danger' : ''
                )}
                onChange={(event: any) => {
                  setStartDate(event);
                }}
                selected={startDate}
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </Col>
            <Col md={2}>
              <div className="d-flex justify-content-center mb-2">
                <div className="fs-48 fw-400">- </div>
              </div>
            </Col>
            <Col md={5}>
              <CustomDatePicker
                className={clsx(
                  'form-control bg-white min-h-60px fs-16 fw-bold ',
                  !endDate ? 'border-danger' : ''
                )}
                onChange={(event: any) => {
                  setEndDate(event);
                }}
                selected={endDate}
                selectsRange={false}
                startDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pt-0 border-top-0">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
          >
            {!loading && (
              <span className="indicator-label">Download report</span>
            )}
            {loading && (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DashboardReport;
