import { Button, Card, Col, Row } from 'react-bootstrap';
import a2 from '../../../../_admin/assets/media/brand/super_meal.png';
import { useNavigate } from 'react-router-dom';
import { CustomSelectWhite } from '../../../custom/Select/CustomSelectWhite';
import { useEffect, useState } from 'react';
import { getCSSVariableValue } from '../../../../_admin/assets/ts/_utils';
import Chart from 'react-apexcharts';
import APICallService from '../../../../api/apiCallService';
import { buyer, master } from '../../../../api/apiEndPoints';
import Method from '../../../../utils/methods';
import { IMAGES } from '../../../../utils/dummyJSON';
import {
  AllCustomers,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../../../utils/constants';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import Loader from '../../../../Global/loader';
import moment from 'moment';
import { error } from '../../../../Global/toast';
import { CustomComponentSelect } from '../../../custom/Select/CustomComponentSelect';
const TabCustomerDashboard = (props: any) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [orderStartDate, setOrderStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [orderEndDate, setOrderEndDate] = useState<any>(new Date());
  const [chartSeries, setChartSeries] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [ordersData, setOrderData] = useState<any>();
  const [topOrderedData, setTopOrderedData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [filterProducts, setFilterProducts] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [dates, setDates] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setOrderLoading(true);
      await fetchReports(startDate, endDate, selectedDistrict);
      await fetchTopOrders(startDate, endDate, selectedDistrict);
      setOrderLoading(false);
      await fetchCategory();
    })();
  }, []);
  const fetchReports = async (
    fromDate: any,
    toDate: any,
    district: any,
    categories?: any
  ) => {
    setLoading(true);
    let customerId = props.customerId || localStorage.getItem('customerId');
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      // fromDate: '2023-02-01',
      // toDate: '2024-02-30',
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '0',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    let apiService = new APICallService(buyer.dashboard, params, {
      id: customerId,
    },'','','',AllCustomers);
    let response = await apiService.callAPI();
    if (response) {
      if (response.ordersSaleData && response.ordersSaleData.length) {
        response.ordersSaleData = Method.populateMissingDates(
          response.ordersSaleData,
          Method.convertDateToFormat(fromDate, 'DD'),
          Method.convertDateToFormat(toDate, 'DD')
        );
        const allDatesSet = new Set([
          ...response.ordersSaleData.map((entry: any) => entry.date),
        ]);
        const allDates: any = Array.from(allDatesSet);
        setDates(allDates);
        let temp = [
          {
            name: 'Orders',
            data: response.ordersSaleData.map((entry: any) => ({
              x: entry.totalOrders,
              y: entry.totalSales.toFixed(2),
            })),
          },
        ];
        setChartData(response.ordersSaleData);
        setChartSeries(temp);
        setOrderData(response);
      } else {
        setChartData([]);
        setChartSeries([]);
        setOrderData(null);
      }
    }
    setLoading(false);
  };
  const fetchTopOrders = async (
    fromDate: any,
    toDate: any,
    district: any,
    categories?: any
  ) => {
    setOrderLoading(true);
    let customerId = props.customerId || localStorage.getItem('customerId');
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '1',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    if (categories !== undefined && categories.length) {
      categories.map((val: any, index: number) => {
        params = { ...params, ['categories[' + index + ']']: val.id };
      });
    }
    let apiService = new APICallService(buyer.dashboard, params, {
      id: customerId,
    },'','','',AllCustomers);
    let response = await apiService.callAPI();
    if (response) {
      if (response.topProducts && response.topProducts.length) {
        setTopOrderedData(response.topProducts);
      } else {
        setTopOrderedData([]);
      }
    }
    setOrderLoading(false);
  };
  const fetchCategory = async () => {
    let apiService = new APICallService(master.categoryList, {
      categoriesDepth: 1,
    },'','','','',AllCustomers);
    let response = await apiService.callAPI();
    if (response.records) {
      let temp: any = [];
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          name: val.title,
          title: val.title,
          id: val._id,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img
                  src={val.image}
                  className="object-fit-contain"
                  alt=""
                />
              </div>
              <label
                className="form-check-label truncate-2 fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
        });
      });
      setFilterProducts(temp);
    }
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startMonth = moment(new Date(startDate)).format('MM');
    const endMonth = moment(new Date(endDate)).format('MM');
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
        } else {
          error(
            'Please select a date range with a minimum one-week difference'
          );
        }
      } else {
        error('Please select a date range within a single month');
      }
    }
  };
  const handleOrderDateChange = async ([startDate, endDate]: any) => {
    setOrderStartDate(startDate);
    setOrderEndDate(endDate);
    if (startDate && endDate) {
      await fetchTopOrders(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDistrict,
        categories
      );
    }
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    await fetchTopOrders(
      Method.convertDateToFormat(orderStartDate, 'YYYY-MM-DD'),
      Method.convertDateToFormat(orderEndDate, 'YYYY-MM-DD'),
      selectedDistrict,
      event
    );
  };
  return (
    <>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          <div className="card-title">
            <h2 className="fs-22 fw-bolder">Orders report</h2>
          </div>
          <div className="card-toolbar">
            <div className="ms-3">
              <CustomDatePicker
                className="bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Card.Header>
        {loading ? (
          <>
            <Card.Body className="py-0">
              <div className="d-flex flex-center min-h-140px">
                <Loader loading={loading} />
              </div>
            </Card.Body>
          </>
        ) : (
          <>
            {chartData.length > 0 ? (
              <>
                <Card.Body>
                  <Row className="g-5">
                    <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {ordersData.totalOrders}{' '}
                          {ordersData.totalOrders > 1 ? 'orders' : 'order'}
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total orders
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {' '}
                          {ordersData.totalCancelledOrders}{' '}
                          {ordersData.totalCancelledOrders > 1
                            ? 'orders'
                            : 'order'}
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total cancelled orders
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {' '}
                          {ordersData.totalProductBought} products
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total products bought
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          TSh{' '}
                          {Method.getGeneralizedAmount(
                            chartData.reduce((p: any, c: any) => {
                              return p + c.totalSales;
                            }, 0)
                          )}{' '}
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total order value
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Chart
                        options={{
                          chart: {
                            fontFamily: 'inherit',
                            type: 'area' as 'area',
                            height: 275,
                            toolbar: {
                              show: false,
                            },
                            dropShadow: {
                              enabled: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'smooth',
                            show: true,
                            width: 3,
                            colors: [baseColor],
                          },
                          legend: {
                            show: false,
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: dates,
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              style: {
                                colors: '#1a1a1a',
                                fontSize: '12px',
                              },
                            },
                            tooltip: {
                              enabled: false,
                            },
                          },
                          // yaxis: {
                          //   labels: {
                          //     align: 'center',
                          //     style: {
                          //       colors: '#7c7c7c',
                          //       fontSize: '12px',
                          //     },
                          //   },
                          // },
                          yaxis: [
                            // {
                            //   seriesName: 'Total Orders',
                            //   opposite: true,
                            //   labels: {
                            //     show: false,
                            //     align: 'center',
                            //     style: {
                            //       colors: '#7c7c7c',
                            //       fontSize: '12px',
                            //     },
                            //   },
                            // },
                            {
                              seriesName: '',
                              labels: {
                                align: 'center',
                                style: {
                                  colors: '#7c7c7c',
                                  fontSize: '12px',
                                },
                                formatter: function (value) {
                                  return Method.getGeneralizedAmount(value); // Adjust locale as needed
                                },
                              },
                            },
                          ],
                          tooltip: {
                            enabled: true,
                            custom: function ({
                              series,
                              seriesIndex,
                              dataPointIndex,
                              w,
                            }) {
                              const monthData = chartData[dataPointIndex];
                              return `<div style='width:164px !important'>
                                  <div class="p-2 mb-0"><span class="fw-500 fs-16 text-dark ms-2 mb-2">Total Orders</span><div class="d-flex align-items-center ms-2 mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">${Method.getGeneralizedAmount(
                                    monthData.totalOrders
                                  )} ${
                                monthData.totalOrders > 1 ? 'order' : 'orders'
                              }</span> </div>
                                    </div>
            <div class='separator my-2'></div>
            <div class="p-2 mb-0 ms-2 mb-2"><span class="fw-500 fs-16 text-dark">Total Sales</span><div class="d-flex align-items-center mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">TSh ${Method.getGeneralizedAmount(
              monthData.totalSales
            )}</span> </div></div>
                             `;
                            },
                          },
                          colors: [baseColor],
                          grid: {
                            borderColor: '#e0e0df',
                            strokeDashArray: 5,
                            yaxis: {
                              lines: {
                                show: true,
                              },
                            },
                          },
                          fill: {
                            type: 'solid',
                            opacity: 0.05,
                          },
                          markers: {
                            size: 0,
                            colors: undefined,
                            strokeColors: '#1b74e4',
                            strokeWidth: 8,
                            strokeOpacity: 1,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            shape: 'circle',
                            radius: 0,
                            onClick: undefined,
                            onDblClick: undefined,
                            showNullDataPoints: false,
                            hover: {
                              size: undefined,
                              sizeOffset: 3,
                            },
                          },
                        }}
                        series={[
                          {
                            name: '',
                            data: chartData.map((entry: any) =>
                              entry.totalSales.toFixed(2)
                            ),
                          },
                        ]}
                        type="area"
                        height="350"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body className="py-0">
                  <div className="d-flex flex-center min-h-140px">
                    <span className="fs-18 fw-medium">
                      No details available for these dates.
                    </span>
                  </div>
                </Card.Body>
              </>
            )}
          </>
        )}
      </Card>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          <div className="card-title">
            <h2 className="fs-22 fw-bolder"> Top ordered Products</h2>
          </div>
          <div className="card-toolbar">
            <div className="min-w-md-193px ">
              <label className="fs-16 fw-medium">Filter by categories</label>
            </div>
            <div className=" me-6">
              <CustomComponentSelect
                isDisabled={loading}
                hideSelectedOptions={false}
                options={filterProducts}
                onChange={(event: any) => {
                  handleCategoryFilter(event);
                }}
                isMulti={true}
              />
            </div>
            <div className="min-w-md-193px">
              <CustomDatePicker
                className="bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleOrderDateChange}
                selectsRange={true}
                startDate={orderStartDate}
                endDate={orderEndDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Card.Header>
        {orderLoading ? (
          <>
            <Card.Body className="py-0">
              <div className="d-flex flex-center min-h-140px">
                <Loader loading={orderLoading} />
              </div>
            </Card.Body>
          </>
        ) : (
          <>
            {topOrderedData.length > 0 ? (
              <>
                <Card.Body>
                  <Row className="gy-5 mt-auto">
                    <Col md={12}>
                      <Card className="border border-r10px h-xl-100">
                        <Card.Body>
                          <div className="hover-scroll-overlay-y pe-6 me-n6 h-400px">
                            <div className="table-responsive">
                              <table className="table table-row-bordered align-middle gs- gy-4 my-0">
                                <thead>
                                  <tr className="fs-16 fw-600 border-bottom-1">
                                    <th className="min-w-225px">
                                      Product name
                                    </th>
                                    <th className="min-w-200px">Unit bought</th>
                                    <th className="min-w-100px">
                                      Total orders
                                    </th>
                                    <th className="min-w-100px">Total value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {topOrderedData.map((val: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-50px border rounded me-3">
                                                <img
                                                  src={val.variant.media[0].url}
                                                  className="object-fit-contain"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="d-flex justify-content-start flex-column">
                                                <span className="fw-600 fs-15">
                                                  {val.variant.title}
                                                </span>
                                                <span className="fs-14 fw-semibold d-block ">
                                                  {
                                                    val.variant.product.business
                                                      .name
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="badge badge-light border-r23px">
                                              <span className="fs-15 fw-600 text-dark p-3 d-block">
                                                {val.quantityTypes
                                                  .map((item: any) => {
                                                    if (
                                                      item.type ===
                                                      CartonWithDozens
                                                    ) {
                                                      return `${item.stockCount} C(D)`;
                                                    }
                                                    if (
                                                      item.type ===
                                                      CartonWithPieces
                                                    ) {
                                                      return `${item.stockCount} C(P)`;
                                                    }
                                                    if (item.type === Dozen) {
                                                      return `${item.stockCount} D`;
                                                    }
                                                    if (item.type === Piece) {
                                                      return `${item.stockCount} P`;
                                                    }
                                                    return '';
                                                  })
                                                  .join(', ')}
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <span className="fs-15 fw-600">
                                              {val.totalOrders}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-15 fw-600">
                                              TSh{' '}
                                              {Method.getGeneralizedAmount(
                                                val.totalSales
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body className="py-0">
                  <div className="d-flex flex-center min-h-140px">
                    <span className="fs-18 fw-medium">
                      No details available for these dates.
                    </span>
                  </div>
                </Card.Body>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};
export default TabCustomerDashboard;
