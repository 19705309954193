import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { KTSVG } from '../../../../../_admin/helpers';
import { CustomComponentSelect } from '../../../../custom/Select/CustomComponentSelect';
import ProductRejected from '../../../../modals/product-rejected';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  All_Products,
  AllProduct,
  AllSeller,
  ApprovedProduct,
  PAGE_LIMIT,
  PendingProduct,
  RejectedProduct,
  View,
} from '../../../../../utils/constants';
import APICallService from '../../../../../api/apiCallService';
import { master, product, seller } from '../../../../../api/apiEndPoints';
import { productJSON } from '../../../../../api/apiJSON/product';
import Loader from '../../../../../Global/loader';
import Pagination from '../../../../../Global/pagination';
import { IMAGES } from '../../../../../utils/dummyJSON';
import { getKey, setKey } from '../../../../../Global/history';
import { listSellerProfileCatalogue } from '../../../../../utils/storeString';
import Method from '../../../../../utils/methods';
import { useAuth } from '../../../auth';
import PermissionModal from '../../../../modals/permission';
const Catalogue = (props: any) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [active, setActive] = useState(PendingProduct);
  const [filterProducts, setFilterProducts] = useState([]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(
    getKey(listSellerProfileCatalogue.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listSellerProfileCatalogue.limit) || PAGE_LIMIT
  );
  const [categories, setCategories] = useState<any>(getKey(listSellerProfileCatalogue.filterCategories) || []);
  const [rejectedProduct, setRejectedProduct] = useState<any>({});
  const [productState, setProductState] = useState(AllProduct);
  const [search, setSearch] = useState<string>(getKey(listSellerProfileCatalogue.search)?.toString() ||'');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showReasonPermissionModal, setShowReasonPermissionModal] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchProducts(
        page,
        pageLimit,
        productState,
        categories,
        search,
        active
      );
      setFetchLoader(false);
      await fetchCategory();
    })();
  }, []);
  const fetchCategory = async () => {
    let apiService = new APICallService(master.categoryList, {
      categoriesDepth: 1,
    },'','','','',AllSeller);
    let response = await apiService.callAPI();
    if (response.records) {
      let temp: any = [];
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          name: val.title,
          title: val.title,
          id: val._id,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img
                  src={val.image}
                  className="object-fit-contain"
                  alt=""
                />
              </div>
              <label
                className="form-check-label fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
        });
      });
      setFilterProducts(temp);
    }
  };
  const fetchProducts = async (
    pageNo: number,
    limit: number,
    state: number,
    categories: any,
    search: string,
    status?: number
  ) => {
    setLoading(true);
    let data = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'title',
      sortOrder: -1,
      searchTerm: search,
      state: state,
      needCount: true,
      status: status,
    };
    if (categories.length > 0) {
      categories.map((val: any, index: number) => {
        data = { ...data, ['categories[' + index + ']']: val.id };
      });
    }
    let apiService = new APICallService(seller.listSellerProduct, data, {
      _id: props.businessId,
    },'','','',AllSeller);
    let response = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setProducts(response.records);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };
  const handleActive = async (event: any) => {
    setActive(event);
    setTotalRecords(0);
    await fetchProducts(
      page,
      pageLimit,
      productState,
      categories,
      search,
      event
    );
    setLoading(false);
  };
  const handleVariationShow = (variantionData: any, index: number) => {
    let temp: any = [...products];
    temp[index].showVariant = !temp[index].showVariant;
    setProducts(temp);
  };
  const updateProductState = async (
    state: boolean,
    id: string,
    isProduct: boolean,
    productIndex: number,
    variantIndex: number
  ) => {
    let temp = [...products];
    let apiService = new APICallService(
      product.updateProductState,
      productJSON.updateProductState({ activate: !state }),
      {
        id: id,
      },'','','',AllSeller
    );
    let response = await apiService.callAPI();
    if (response) {
      temp[productIndex].variants[variantIndex].variant.active = !state;
    }
    if (productState !== AllProduct) {
      temp.splice(productIndex, 1);
    }
    setProducts(temp);
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    setKey(listSellerProfileCatalogue.filterCategories, event, false);
    await fetchProducts(1, pageLimit, productState, event, search, active);
  };
  const handleProductStateChange = async (event: any) => {
    await fetchProducts(1, pageLimit, event.value, categories, search, active);
    setProductState(event.value);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listSellerProfileCatalogue.search, value, false);
    await fetchProducts(1, pageLimit, productState, categories, value, active);
  };
  const rejectModal = (value: any) => {
    setRejectedProduct(value);
    setShowRejectModal(true);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listSellerProfileCatalogue.page, val);
    await fetchProducts(
      val,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listSellerProfileCatalogue.page, val + 1);
    await fetchProducts(
      val + 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listSellerProfileCatalogue.page, val - 1);
    await fetchProducts(
      val - 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listSellerProfileCatalogue.page, 1);
    setKey(listSellerProfileCatalogue.limit, parseInt(event.target.value));
    await fetchProducts(
      1,
      event.target.value,
      productState,
      categories,
      search,
      active
    );
  };
  return (
    <>
    {showPermissionModal && (
        <PermissionModal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        moduleName = {'Product'}
      />
      )}
      {showReasonPermissionModal && (
        <PermissionModal
        show={showReasonPermissionModal}
        onHide={() => setShowReasonPermissionModal(false)}
        moduleName = {'Product'}
      />
      )}
      {showRejectModal ? (
        <ProductRejected
          show={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          rejectedProduct={rejectedProduct}
        />
      ) : (
        <></>
      )}
      <Col xs={12}>
        <Card className="bg-light border mb-2">
          <Card.Body>
            <Row className="align-items-center">
              <Col
                xl={4}
                lg={12}
                className="mb-xl-0 mb-5"
              >
                <div className="d-flex align-items-center position-relative me-lg-4">
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-3 position-absolute ms-3"
                  />
                  <input
                    type="text"
                    id="kt_filter_search"
                    className="form-control form-control-white min-h-60px form-control-lg ps-10"
                    placeholder="Search by product name…"
                    onChange={(event: any) => {
                      handleSearch(event.target.value.trimStart());
                    }}
                    value={search}
                  />
                </div>
              </Col>
              <Col xl={8}>
                <Row className="align-items-center justify-content-xl-end">
                  <Col
                    md="auto"
                    className="mb-md-0 mb-5"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Filter products
                        </label>
                      </Col>
                      <Col xs>
                        <div className="min-w-lg-300px mw-lg-300px">
                          <CustomComponentSelect
                            // onChange={(options: any) => {
                            //   if (Array.isArray(options)) {
                            //   }
                            // }}
                            // isDisabled={loading}
                            isDisabled={loading}
                            hideSelectedOptions={false}
                            isSearchable={true}
                            options={filterProducts}
                            onChange={(event: any) => {
                              handleCategoryFilter(event);
                            }}
                            defaultValue = {categories}
                            isMulti={true}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* {active===ApprovedProduct? (
                       <Col md={"auto"}>
                        <CustomSelectWhite
                          defaultValue={[
                            {
                              value: AllProduct,
                              name: "All products",
                              label: (
                                <>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    All products
                                  </span>
                                </>
                              ),
                            },
                          ]}
                          isDisabled={loading}
                          options={productOptions}
                          onChange={(event: any) => {
                            handleProductStateChange(event);
                          }}
                          isSearchable={false}
                          isMulti={false}
                        />
                      </Col>
                    ):(
                      <></>
                    )} */}
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                    <th className="min-w-275px">Product name</th>
                    <th className="min-w-200px">Status</th>
                    <th className="min-w-200px">Total sales</th>
                    <th className="min-w-70px">Activate / Deactivate</th>
                    <th className="w-200px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="w-100 d-flex justify-content-center">
                          <Loader loading={loading} />
                        </div>
                      </td>
                    </tr>
                  ) : products.length ? (
                    products.map((productVal: any, index: number) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <></>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px border me-5">
                                  <img
                                    src={
                                      productVal.variants[0].variant.media[0]
                                        .url
                                    }
                                    className="object-fit-contain"
                                    alt=""
                                  />
                                </div>
                                <div className="fs-15 fw-600 mw-lg-175px">
                                  {productVal.title.replace(/\s*\)\s*/g, ')')}
                                </div>
                              </div>
                            </td>
                            <td className="min-w-200px">
                              {productVal.variants.length &&
                              productVal.variants[0].variant[
                                'variantType'
                              ][0] != undefined ? (
                                <span className="fs-20 fw-600">-</span>
                              ) : (
                                <>
                                  {productVal.variants[0].variant.status ===
                                  PendingProduct ? (
                                    <span className="badge badge-light-warning border-r4px p-3 fs-14 fw-600 text-dark">
                                      Verification Pending
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {productVal.variants[0].variant.status ===
                                  ApprovedProduct ? (
                                    <>
                                      {productVal.active ? (
                                        <span className="badge badge-light-success border-r4px p-3 fs-14 fw-600 text-dark">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="badge badge-light border-r4px p-3 fs-14 fw-600 text-dark">
                                          Deactivated
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {productVal.variants[0].variant.status ===
                                  RejectedProduct ? (
                                    <span className="badge badge-light-danger border-r4px p-3 fs-14 fw-600 text-dark">
                                      Rejected
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              {productVal.variants.length &&
                              productVal.variants[0].variant[
                                'variantType'
                              ][0] != undefined ? (
                                <span className="fs-20 fw-600">-</span>
                              ) : (
                                <>
                                  <span className="d-block fs-15 fw-600 text-dark">
                                    -
                                  </span>
                                </>
                              )}
                            </td>
                            <td>
                              {productVal.variants.length &&
                              productVal.variants[0].variant[
                                'variantType'
                              ][0] != undefined ? (
                                <span className="fs-20 fw-600">-</span>
                              ) : (
                                <label className="form-check form-switch form-check-custom form-check-solid ">
                                  <input
                                    className="form-check-input form-check-success w-50px h-30px"
                                    type="checkbox"
                                    name="notifications"
                                    value={
                                      productVal.variants[0].variant['_id']
                                    }
                                    checked={
                                      productVal.variants[0].variant.active
                                    }
                                    onChange={() => {
                                      updateProductState(
                                        productVal.variants[0].variant.active,
                                        productVal.variants[0].variant['_id'],
                                        true,
                                        index,
                                        0
                                      );
                                    }}
                                  />
                                </label>
                              )}
                            </td>
                            <td className="text-end">
                              {productVal.variants.length &&
                              productVal.variants[0].variant[
                                'variantType'
                              ][0] != undefined ? (
                                <div className="d-flex flex-row justify-content-end">
                                  <span
                                    className="fs-16 fw-600 text-gray text-hover-primary cursor-pointer me-3"
                                    onClick={() => {
                                      handleVariationShow(
                                        products.variants,
                                        index
                                      );
                                    }}
                                  >
                                    {productVal.showVariant ? 'Hide' : 'View'}{' '}
                                    {productVal.variants.length} variations
                                  </span>
                                  {/* <Button
                                    variant="link"
                                    className="btn-flush"
                                  >
                                    <img
                                      width={24}
                                      height={7}
                                      src={IMAGES.ThreeDot}
                                      alt=""
                                    />
                                  </Button> */}
                                </div>
                              ) : (
                                <>
                                  <div className="d-flex flex-row justify-content-end">
                                  {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(All_Products, View, currentUser) ? (
                                    <Button
                                      variant="primary"
                                      className="btn-active-light-primary fs-14 fw-600 me-3"
                                      onClick={() => {
                                        navigate(
                                          '/all-products/product-details',
                                          {
                                            state:
                                            {id: productVal.variants[0].variant['_id'], moduleName: AllSeller}
                                          }
                                        );
                                      }}
                                    >
                                      View details
                                    </Button>
                                    ) : (
                                      <></>
                                    )}
                                    {Method.hasPermission(AllSeller, View, currentUser) && !Method.hasPermission(All_Products, View, currentUser) ? (
                                    <Button
                                      variant="primary"
                                      className="btn-active-light-primary fs-14 fw-600 me-3"
                                      onClick={() =>{setShowPermissionModal(true)}}
                                    >
                                      View details
                                    </Button>
                                    ) : (
                                      <></>
                                    )}
                                    {/* <Button
                                      variant="link"
                                      className="btn-flush"
                                    >
                                      <img
                                        width={24}
                                        height={7}
                                        src={IMAGES.ThreeDot}
                                        alt=""
                                      />
                                    </Button> */}
                                  </div>
                                  {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(All_Products, View, currentUser) ? (
                                    <>
                                  {active === RejectedProduct ? (
                                    <Button
                                      variant="light-primary"
                                      className="me-3 btn-active-light-primary fs-14 fw-600"
                                      onClick={() => {
                                        rejectModal(productVal.variants[0]);
                                      }}
                                    >
                                      View Reason
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  </>
                                  ) : (
                                    <></>
                                  )}
                                  {Method.hasPermission(AllSeller, View, currentUser) && !Method.hasPermission(All_Products, View, currentUser) ? (
                                    <>
                                  {active === RejectedProduct ? (
                                    <Button
                                      variant="light-primary"
                                      className="me-3 btn-active-light-primary fs-14 fw-600"
                                      onClick={() =>{setShowReasonPermissionModal(true)}}
                                    >
                                      View Reason
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          {productVal.showVariant ? (
                            productVal.variants.map(
                              (variantVal: any, variantIndex: number) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="symbol symbol-50px border me-5">
                                            <img
                                              src={
                                                variantVal.variant.media[0].url
                                              }
                                              className="object-fit-contain"
                                              alt=""
                                            />
                                          </div>
                                          <div className="fs-15 fw-600 mw-lg-175px">
                                            {variantVal.variant.title.replace(
                                              /\s*\)\s*/g,
                                              ')'
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {/* <span className="fs-15 fw-600">
                                                            {
                                                              variantVal.variant
                                                                .product
                                                                .business.name
                                                            }
                                                          </span> */}
                                        {variantVal.variant.status ===
                                        PendingProduct ? (
                                          <span className="badge badge-light-warning border-r4px p-3 fs-14 fw-600 text-dark">
                                            Verification Pending
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        {variantVal.variant.status ===
                                        ApprovedProduct ? (
                                          <>
                                            {productVal.active ? (
                                              <span className="badge badge-light-success border-r4px p-3 fs-14 fw-600 text-dark">
                                                Active
                                              </span>
                                            ) : (
                                              <span className="badge badge-light border-r4px p-3 fs-14 fw-600 text-dark">
                                                Deactivated
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {variantVal.variant.status ===
                                        RejectedProduct ? (
                                          <span className="badge badge-light-danger border-r4px p-3 fs-14 fw-600 text-dark">
                                            Rejected
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {' '}
                                        <span className="d-block fs-15 fw-600 text-dark">
                                          -
                                        </span>
                                      </td>
                                      <td>
                                        <label className="form-check form-switch form-check-custom form-check-solid ">
                                          <input
                                            className="form-check-input form-check-success w-50px h-30px"
                                            type="checkbox"
                                            name="notifications"
                                            value={variantVal.variant._id}
                                            checked={variantVal.variant.active}
                                            onChange={() => {
                                              updateProductState(
                                                variantVal.variant.active,
                                                variantVal.variant._id,
                                                false,
                                                index,
                                                variantIndex
                                              );
                                            }}
                                          />
                                        </label>
                                      </td>
                                      <td className="text-end">
                                      {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(All_Products, View, currentUser) ? (
                                        <Button
                                          variant="primary"
                                          className="me-3 btn-active-light-primary fs-14 fw-600"
                                          onClick={() => {
                                            navigate(
                                              '/all-products/product-details',
                                              {
                                                state: {id: variantVal.variant['_id'], moduleName: AllSeller},
                                              }
                                            );
                                          }}
                                        >
                                          View details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                      {Method.hasPermission(AllSeller, View, currentUser) && !Method.hasPermission(All_Products, View, currentUser) ? (
                                        <Button
                                          variant="primary"
                                          className="me-3 btn-active-light-primary fs-14 fw-600"
                                          onClick={() =>{setShowPermissionModal(true)}}
                                        >
                                          View details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                       {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(All_Products, View, currentUser) ? (
                                        <>
                                        {active === RejectedProduct ? (
                                          <Button
                                            variant="light-primary"
                                            className="me-3 btn-active-light-primary fs-14 fw-600"
                                            onClick={() => {
                                              rejectModal(variantVal);
                                            }}
                                          >
                                            View Reason
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                      ) : (
                                        <></>
                                      )}
                                      {Method.hasPermission(AllSeller, View, currentUser) && !Method.hasPermission(All_Products, View, currentUser) ? (
                                        <>
                                        {active === RejectedProduct ? (
                                          <Button
                                            variant="light-primary"
                                            className="me-3 btn-active-light-primary fs-14 fw-600"
                                            onClick={() =>{setShowPermissionModal(true)}}
                                          >
                                            View Reason
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                      ) : (
                                        <></>
                                      )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                          No Data found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!loading ? (
          <>
            {products.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={(event: any) => {
                  handleCurrentPage(event);
                }}
                handleNextPage={(event: any) => {
                  handleNextPage(event);
                }}
                handlePreviousPage={(event: any) => {
                  handlePreviousPage(event);
                }}
                pageLimit={pageLimit}
                handlePageLimit={(event: any) => {
                  handlePageLimit(event);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Col>
    </>
  );
};
export default Catalogue;
