import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import APICallService from '../../../api/apiCallService';
import { contactInquires } from '../../../api/apiEndPoints';
import { inquiresJSON } from '../../../api/apiJSON/contactInquires';
import { IGetInquires } from '../../../types/responseIndex';
import BuyerContact from './tabs/buyer-contact';
import SellerContact from './tabs/seller-contact';
import { getPageLimit, setPageLimit, useAuth } from '../auth';
import { ContactInquires, PAGE_LIMIT } from '../../../utils/constants';
import { getKey, setKey } from '../../../Global/history';
import { listContactInquiries } from '../../../utils/storeString';
import Method from '../../../utils/methods';
const ContactInquiries = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [active, setActive] = useState('0');
  const [fetchLoading, setFetchLoading] = useState(false);
  const [inquiries, setInquiries] = useState<IGetInquires | any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(listContactInquiries.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listContactInquiries.limit) || PAGE_LIMIT
  );
  const [tab, setTab] = useState([
    {
      name: 'Seller Contact',
      content: (
        <SellerContact
          inquiries={inquiries}
          fetchLoading={true}
          loading={loading}
          searchTerm={searchTerm}
        />
      ),
      value: '1',
    },
    {
      name: 'Buyer Contact',
      content: (
        <BuyerContact
          inquiries={inquiries}
          fetchLoading={fetchLoading}
          loading={loading}
        />
      ),
      value: '2',
    },
  ]);
  const [currentTab, setCurrentTab] = useState<any>(
    getKey(listContactInquiries.currentTab)
      ? () => {
          let temp: any = getKey(listContactInquiries.currentTab);
          let tabTemp = {};
          if (temp.toString() === '2') {
            tabTemp = {
              name: 'Buyer Contact',
              content: (
                <BuyerContact
                  inquiries={inquiries}
                  fetchLoading={fetchLoading}
                  loading={loading}
                />
              ),
              value: '2',
            };
          }
          if (temp.toString() === '1') {
            tabTemp = {
              name: 'Seller Contact',
              content: (
                <SellerContact
                  inquiries={inquiries}
                  fetchLoading={true}
                  loading={loading}
                  searchTerm={searchTerm}
                />
              ),
              value: '1',
            };
          }
          return tabTemp;
        }
      : {
          name: 'Seller Contact',
          content: (
            <SellerContact
              inquiries={inquiries}
              fetchLoading={true}
              loading={loading}
              searchTerm={searchTerm}
            />
          ),
          value: '1',
        }
  );
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(ContactInquires, currentUser)) {
        return window.history.back();
      }
      setFetchLoading(true);
      if (getKey(listContactInquiries.currentTab)) {
        let temp: any = getKey(listContactInquiries.currentTab);
        if (temp.toString() === '1') {
          await fetchInquiryData(page, pageLimit, searchTerm);
        } else {
          await fetchBuyerInquiryData(page, pageLimit, searchTerm);
        }
      } else {
        await fetchInquiryData(page, pageLimit, searchTerm);
      }
      setFetchLoading(false);
    })();
  }, []);
  const fetchInquiryData = async (
    pageNo: number,
    limit: number,
    search: string
  ) => {
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      searchTerm: search,
    };
    let apiService = new APICallService(
      contactInquires.listInquires,
      inquiresJSON.listInquires(params),
      '','','','',ContactInquires
    );
    let response = await apiService.callAPI();
    if (response) {
      setTotalRecords(response.total);
      setInquiries(response.records);
      setCurrentTab({
        name: 'Seller Contact',
        content: (
          <SellerContact
            inquiries={response.records}
            fetchLoading={false}
            loading={loading}
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            currentPage={pageNo}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={response.total}
          />
        ),
        value: '1',
      });
    }
  };
  const fetchBuyerInquiryData = async (
    pageNo: number,
    limit: number,
    search: string
  ) => {
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      searchTerm: search,
    };
    let apiService = new APICallService(
      contactInquires.listBuyerInquires,
      inquiresJSON.listInquires(params),
      '','','','',ContactInquires
    );
    let response = await apiService.callAPI();
    if (response) {
      setTotalRecords(response.total);
      setInquiries(response.records);
      setCurrentTab({
        name: 'Buyer Contact',
        content: (
          <BuyerContact
            inquiries={response.records}
            fetchLoading={false}
            loading={loading}
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            currentPage={pageNo}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={response.total}
          />
        ),
        value: '2',
      });
    }
  };
  const handleSelectTab = async (tab: any) => {
    setFetchLoading(true);
    setKey(listContactInquiries.currentTab, parseInt(tab.value));
    if (tab.value === '2') {
      await setInquiries([]);
      await setTotalRecords(0);
      await setPage(1);
      await setCurrentTab({
        name: 'Buyer Contact',
        content: (
          <BuyerContact
            inquiries={[]}
            fetchLoading={true}
            loading={loading}
            handleSearch={handleSearch}
            currentPage={1}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={totalRecords}
          />
        ),
        value: '2',
      });
      await fetchBuyerInquiryData(1, pageLimit, searchTerm);
    } else {
      await setInquiries([]);
      await setTotalRecords(0);
      await setPage(1);
      await setCurrentTab({
        name: 'Seller Contact',
        content: (
          <SellerContact
            inquiries={[]}
            fetchLoading={true}
            loading={loading}
            handleSearch={handleSearch}
            currentPage={1}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={totalRecords}
          />
        ),
        value: '1',
      });
      await fetchInquiryData(1, pageLimit, searchTerm);
      setFetchLoading(false);
    }
    setFetchLoading(false);
  };
  const handleCurrentPage = async (val: number, currentTabValue: string) => {
    setPage(val);
    setKey(listContactInquiries.page, val);
    if (currentTabValue === '1') {
      await fetchInquiryData(val, pageLimit, searchTerm);
    } else {
      await fetchBuyerInquiryData(val, pageLimit, searchTerm);
    }
  };
  const handleNextPage = async (val: number, currentTabValue: string) => {
    setPage(val + 1);
    setKey(listContactInquiries.page, val + 1);
    if (currentTabValue === '1') {
      await fetchInquiryData(val + 1, pageLimit, searchTerm);
    } else {
      await fetchBuyerInquiryData(val + 1, pageLimit, searchTerm);
    }
  };
  const handlePreviousPage = async (val: number, currentTabValue: string) => {
    setPage(val - 1);
    setKey(listContactInquiries.page, val - 1);
    if (currentTabValue === '1') {
      await fetchInquiryData(val - 1, pageLimit, searchTerm);
    } else {
      await fetchBuyerInquiryData(val - 1, pageLimit, searchTerm);
    }
  };
  const handlePageLimit = async (event: any, currentTabValue: string) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listContactInquiries.page, 1);
    setKey(listContactInquiries.limit, parseInt(event.target.value));
    if (currentTabValue === '1') {
      await fetchInquiryData(1, event.target.value, searchTerm);
    } else {
      await fetchBuyerInquiryData(1, event.target.value, searchTerm);
    }
  };
  const handleSearch = async (value: string, tab: string) => {
    setFetchLoading(true);
    if (tab === '1') {
      setCurrentTab({
        name: 'Seller Contact',
        content: (
          <SellerContact
            inquiries={[]}
            fetchLoading={true}
            loading={loading}
            handleSearch={handleSearch}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={totalRecords}
          />
        ),
        value: '1',
      });
      setSearchTerm(value);
      await fetchInquiryData(page, pageLimit, value);
    } else {
      setCurrentTab({
        name: 'Buyer Contact',
        content: (
          <BuyerContact
            inquiries={[]}
            fetchLoading={true}
            loading={loading}
            handleSearch={handleSearch}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            pageLimit={pageLimit}
            handlePageLimit={handlePageLimit}
            total={totalRecords}
          />
        ),
        value: '2',
      });
      await fetchBuyerInquiryData(page, pageLimit, value);
    }
    setSearchTerm(searchTerm);
    setFetchLoading(false);
  };
  const createTabs = () => {
    const localTab = tab;
    let current = currentTab;
    const allTabs = localTab.map((tab) => {
      return (
        <li className="nav-item">
          <a
            href={'/'}
            className={
              current.value.toString() === tab.value.toString()
                ? 'nav-link active fs-16 fw-600 pb-5 w-lg-140px text-active-dark '
                : 'nav-link fs-16 fw-600 pb-5 w-lg-140px text-hover-dark text-active-dark'
            }
            data-bs-toggle="tab"
            onClick={() => handleSelectTab(tab)}
          >
            {tab.name}
          </a>
        </li>
      );
    });
    return allTabs;
  };
  return (
    <div className="p-9">
      <Row className="align-items-center">
        <Col sm>
          <h1 className="fs-22 fw-bolder">Contact inquiries</h1>
        </Col>
      </Row>
      <Card className="border border-r10px mt-5">
        <Card.Body>
          <Row>
            <Col>
              <div className="hover-scroll-x">
                <div className="d-grid">
                  <ul className="nav nav-tabs flex-nowrap text-nowrap nav-line-tabs nav-line-tabs-2x text-center mb-5 fs-6">
                    {createTabs()}
                  </ul>
                </div>
              </div>
              <div
                className="tab-content"
                id="myTabContent"
              >
                <>{currentTab.content}</>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ContactInquiries;
