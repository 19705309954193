import { Card, Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../custom/DateRange/dateRange.css';
import APICallService from '../../../../api/apiCallService';
import { inventory } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import { inventoryJSON } from '../../../../api/apiJSON/inventory';
import { getPageLimit, setPageLimit } from '../../auth';
import Pagination from '../../../../Global/pagination';
import Method from '../../../../utils/methods';
import {
  AddedOnOrderCancellation,
  AddedOnOrderModification,
  AddedOnReturn,
  CartonWithDozens,
  CartonWithPieces,
  DeductedOnDamage,
  DeductedOnMissing,
  DeductedOnOrderModification,
  DeductedOnReturnToSeller,
  DeductedOnSale,
  DirectlyReceived,
  Dozen,
  ManuallyAdded,
  ManuallyDeducted,
  PAGE_LIMIT,
  Piece,
  ShipmentThroughAdded,
  AddedOnConversion,
  DeductedOnConversion,
  All_Products,
  MasterProduct,
} from '../../../../utils/constants';
import clsx from 'clsx';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import { operationJSON } from '../../../../utils/staticJSON';
import { getKey, setKey } from '../../../../Global/history';
import { listProductDetails } from '../../../../utils/storeString';
const ProductInventoryHistory = (props: any) => {
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(
    getKey(listProductDetails.filterStartDate)
    ? new Date(getKey(listProductDetails.filterStartDate)?.toString() || '')
    : null
  );
  const [history, setHistory] = useState<any>([]);
  const [status, setStatus] = useState<any>(getKey(listProductDetails.filterInventory));
  const [statusValue, setStatusValue] = useState<any>(getKey(listProductDetails.filterInventoryName) || null)
  const [endDate, setEndDate] = useState<any>(
    getKey(listProductDetails.filterEndDate)
    ? new Date(getKey(listProductDetails.filterEndDate)?.toString() || '')
    : null
  );
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails(page, pageLimit, startDate, endDate, status);
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async (
    pageNo: number,
    limit: number,
    fromDate?: string,
    toDate?: string,
    sourceTypes?: any
  ) => {
    setLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: pageNo === 1,
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
    console.log('hii',sourceTypes);
    if (sourceTypes?.length > 0) {
      sourceTypes.map((val: any, index: number) => {
        params = { ...params, ['sourceTypes[' + index + ']']: val };
      });
    }
    let apiService = new APICallService(
      inventory.listInventoryVarianTransaction,
      params,
      { id: props.productDetails._id },
      '','','',All_Products
    );
    let response = await apiService.callAPI();
    if (response.records.length) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
      setHistory(response.records);
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    await fetchDetails(val, pageLimit, startDate, endDate, status);
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    await fetchDetails(val + 1, pageLimit, startDate, endDate, status);
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    await fetchDetails(val - 1, pageLimit, startDate, endDate, status);
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    await fetchDetails(1, event.target.value, startDate, endDate, status);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setKey(listProductDetails.filterStartDate, startDate, false);
    setKey(listProductDetails.filterEndDate, endDate, false);
    if (startDate && endDate) {
      setHistory([]);
      setTotalRecords(0);
      await fetchDetails(
        page,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        status
      );
    }
  };
  const handleStatusFilter = async (value: string) => {
    let temp: any = [];
    setHistory([]);
    setTotalRecords(0);
    setKey(listProductDetails.filterInventoryName, value, false);
    if (value === '1') {
      temp = ['c1', 'c3', 'c4'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '2') {
      temp = ['c2'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '3') {
      temp = ['d2'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '4') {
      temp = ['d3', 'd4', 'd5'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '5') {
      temp = ['d1'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    setStatus(temp);
    setKey(listProductDetails.filterInventory,temp,false);
  };
  const getTypeLabel = (type: string) => {
    switch (type) {
      case ShipmentThroughAdded:
      case ManuallyAdded:
      case DirectlyReceived:
        return 'New Stock Updated';
      case AddedOnReturn:
        return 'Customer return';
      case DeductedOnSale:
        return 'New order';
      case DeductedOnReturnToSeller:
        return 'Goods return request';
      case ManuallyDeducted:
      case DeductedOnMissing:
      case DeductedOnDamage:
        return 'Missing/Damaged goods';
      case DeductedOnOrderModification:
      case AddedOnOrderCancellation:
      case AddedOnOrderModification:
        return 'Order Modified';
      case AddedOnConversion:
        return 'Added On Good Conversion';
      case AddedOnConversion:
      case DeductedOnConversion:
        return 'Deducted On Good Conversion';
      default:
        return '';
    }
  };
  return (
    <>
      {fetchLoader ? (
        <>
          <Row>
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0 ">
                  <Row className="align-items-center h-250px">
                    <div className="d-flex justify-content-center">
                      <Loader loading={fetchLoader} />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <Card className="bg-light border mb-7">
                <Card.Body className="px-7">
                  <Row className="align-items-center">
                    <Col
                      xl={4}
                      lg={12}
                      className="mb-xl-0 mb-5"
                    >
                      <div className="d-flex align-items-center position-relative me-lg-4">
                        {/* <KTSVG
                          path="/media/icons/duotune/general/gen021.svg"
                          className="svg-icon-3 position-absolute ms-3"
                        />
                        <input
                          type="text"
                          id="kt_filter_search"
                          className="form-control form-control-white min-h-60px form-control-lg ps-10"
                          placeholder="Search by Operation..."
                          value={search}
                          onChange={(event: any) => {
                            handleSearch(event.target.value.trimStart());
                          }}
                        /> */}
                        <CustomSelect
                          options={operationJSON}
                          backgroundColor="white"
                          onChange={(event: any) => {
                            handleStatusFilter(event.value);
                          }}
                          default = {operationJSON.find(option => option?.value === statusValue)}
                        />
                      </div>
                    </Col>
                    <Col xl={8}>
                      <Row className="align-items-center justify-content-xl-end">
                        <Col
                          md="auto"
                          className="mb-md-0 mb-5"
                        >
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <label
                                htmlFor=""
                                className="fs-16 fw-500"
                              >
                                Filter by dates
                              </label>
                            </Col>
                            <Col xs>
                              <div className="min-w-lg-300px mw-lg-300px">
                                <DatePicker
                                  className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-225px"
                                  selected={startDate}
                                  onChange={handleChange}
                                  selectsRange
                                  startDate={startDate}
                                  endDate={endDate}
                                  dateFormat="dd/MM/yyyy"
                                  showFullMonthYearPicker
                                  maxDate={new Date()}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table align-middle table-rounded table-row-bordered gs-7 gy-6 mb-0 no-footer">
                      <thead>
                        <tr className="text-start fw-bold fs-16 gs-0 border-bottom">
                          <th
                            className="min-w-200px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Date & Time
                          </th>
                          <th
                            className="min-w-50px w-100px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Carton
                          </th>
                          <th
                            className="min-w-125px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Dozen
                          </th>
                          <th
                            className="min-w-125px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Piece
                          </th>
                          <th
                            className="min-w-150px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Operation type
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <>
                            <td colSpan={4}>
                              <div className="w-100 d-flex justify-content-center">
                                <Loader loading={loading} />
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            {history && history.length ? (
                              <>
                                {history.map((historyVal: any) => {
                                  return (
                                    <tr className="odd">
                                      <td className="fs-15 fw-600">
                                        <span className="text-dark d-block">
                                          {Method.convertDateToDDMMYYYYHHMM(
                                            historyVal._createdAt,
                                            ' - '
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === CartonWithDozens ||
                                            item.type === CartonWithPieces
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification ||
                                                historyVal.source.type ===
                                                  AddedOnConversion
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification ||
                                                historyVal.source.type ===
                                                  DeductedOnConversion
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification ||
                                            historyVal.source.type ===
                                              AddedOnConversion
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification ||
                                            historyVal.source.type ===
                                              DeductedOnConversion
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type ===
                                                    CartonWithDozens ||
                                                  item.type === CartonWithPieces
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === Dozen
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification ||
                                                historyVal.source.type ===
                                                  AddedOnConversion
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification ||
                                                historyVal.source.type ===
                                                  DeductedOnConversion
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification ||
                                            historyVal.source.type ===
                                              AddedOnConversion
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification ||
                                            historyVal.source.type ===
                                              DeductedOnConversion
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Dozen
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === Piece
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification ||
                                                historyVal.source.type ===
                                                  AddedOnConversion
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification ||
                                                historyVal.source.type ===
                                                  DeductedOnConversion
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification ||
                                            historyVal.source.type ===
                                              AddedOnConversion
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification ||
                                            historyVal.source.type ===
                                              DeductedOnConversion
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Piece
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="badge badge-light border-r23px">
                                          <span className="fs-15 fw-600 text-dark p-3 px-4">
                                            {getTypeLabel(
                                              historyVal.source.type
                                            )}
                                            {historyVal.source.refKey
                                              ? ` - #${historyVal.source.refKey}`
                                              : ''}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={4}>
                                  <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                    No Data found
                                  </div>
                                </td>
                              </tr>
                            )}{' '}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {!fetchLoader && history.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={handleCurrentPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                handlePageLimit={handlePageLimit}
                pageLimit={pageLimit}
              />
            ) : (
              <></>
            )}
          </Row>
        </>
      )}
    </>
  );
};
export default ProductInventoryHistory;
