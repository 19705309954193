/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../app/modules/auth';
import {
  AllCustomers,
  AllModules,
  AllSeller,
  BuyerContact,
  GoodsRequest,
  GoodsWarehouse,
  KycVerifications,
  LowStockLists,
  MarkRouteAsCompleted,
  OrdersDelivery,
  PAGE_LIMIT,
  PaymentToSellers,
  PendingCertificate,
  ReceivableReports,
  ReceivedDirectGood,
  RefundsToCustomer,
  SellerContact,
  SellersSalesReport,
} from '../../../../utils/constants';
import { AUTH } from '../../../../api/apiEndPoints';
import APICallService from '../../../../api/apiCallService';
import Skeleton, { SkeletonTheme } from '../../../../app/custom/skeleton/index';
import Method from '../../../../utils/methods';
import { useNavigate } from 'react-router-dom';
import { setKey } from '../../../../Global/history';
import { listContactInquiries } from '../../../../utils/storeString';
const HeaderNotificationsMenu = (props: any) => {
  const [notification, setNotification] = useState<any>(undefined);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    setNotification([]);
  }, []);
  useEffect(() => {
    (async () => {
      if (props.show) {
        setLoading(true);
        await fetchNotification(page);
        setLoading(false);
      }
    })();
  }, [props.show]);
  const handleScroll = async (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (!(notification && notification.length === totalRecords)) {
        let tempPage = page;
        tempPage = tempPage + 1;
        setPage(tempPage);
        await fetchNotification(tempPage);
      }
    }
  };
  const fetchNotification = async (pageNo: number) => {
    let params: any = {
      pageNo: pageNo,
      limit: PAGE_LIMIT,
      sortKey: '_createdAt',
      sortOrder: -1,
    };
    let type: any = [];
    if (!Method.hasModulePermission(AllModules, currentUser)) {
      if (
        Method.hasModulePermission(AllCustomers, currentUser) ||
        Method.hasModulePermission(RefundsToCustomer, currentUser) ||
        Method.hasModulePermission(RefundsToCustomer, currentUser) ||
        Method.hasModulePermission(ReceivableReports, currentUser)
      ) {
        type.push(1);
      }
      if (
        Method.hasModulePermission(AllSeller, currentUser) ||
        Method.hasModulePermission(KycVerifications, currentUser) ||
        Method.hasModulePermission(PendingCertificate, currentUser) ||
        Method.hasModulePermission(PaymentToSellers, currentUser) ||
        Method.hasModulePermission(SellersSalesReport, currentUser)
      ) {
        type.push(2);
      }
      if (Method.hasModulePermission(OrdersDelivery, currentUser)) {
        type.push(3);
      }
      if (
        Method.hasModulePermission(GoodsWarehouse, currentUser) ||
        Method.hasModulePermission(LowStockLists, currentUser) ||
        Method.hasModulePermission(GoodsRequest, currentUser) ||
        Method.hasModulePermission(ReceivedDirectGood, currentUser)
      ) {
        type.push(4);
      }
    }
    if (type.length)
      type.map((val: any, index: number) => {
        params = { ...params, ['type[' + index + ']']: val };
      });
    let apiService = new APICallService(AUTH.notification, params);
    let response = await apiService.callAPI();
    if (response) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
      let data: any = notification ? [...notification] : [];
      response.records.map((val: any) => {
        data.push(val);
      });
      setNotification(data);
    }
  };
  return (
    <div
      className={clsx(
        'menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px',
        props.show ? 'show' : ''
      )}
      popper-placement="bottom-end"
      data-kt-menu="true"
      style={{
        zIndex: 105,
        position: 'fixed',
        inset: '0px 0px auto auto',
        margin: '0px',
        transform: 'translate(-85px, 65px)',
      }}
    >
      <div className="d-flex flex-column rounded-top">
        <h3 className="text-dark fw-bold px-6 my-6">Notifications</h3>
      </div>
      <div className="border-bottom"></div>
      <div
        className="scroll-y mh-375px "
        onScroll={handleScroll}
      >
        {loading ? (
          [1, 2, 3, 4, 5].map((val: any, index: number) => {
            return (
              <>
                <div className="px-3 my-4">
                  <div className="d-flex mt-3">
                    <SkeletonTheme
                      baseColor="#fafafa"
                      highlightColor="#f7f7f7"
                    >
                      <Skeleton
                        count={1}
                        width={'325px'}
                        height={'100%'}
                        inline
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-flex mt-1">
                    <SkeletonTheme
                      baseColor="#fafafa"
                      highlightColor="#f7f7f7"
                    >
                      <Skeleton
                        count={1}
                        width={'186px'}
                        height={'100%'}
                        inline
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                {index !== 4 ? <div className="border-bottom"></div> : <></>}
              </>
            );
          })
        ) : notification && notification.length ? (
          notification.map((notifVal: any, index: number) => (
            <>
              <div
                key={`alert${index}`}
                className="d-flex flex-stack px-3 my-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (notifVal.type === BuyerContact) {
                    setKey(listContactInquiries.currentTab, 2);
                    navigate('contact-inquiries/inquiries');
                  } else if (notifVal.type === SellerContact) {
                    setKey(listContactInquiries.currentTab, 1);
                    navigate('contact-inquiries/inquiries');
                  } else if (notifVal.type === MarkRouteAsCompleted) {
                    navigate('/orders/route-details', {
                      state: {
                        vehicleId: notifVal.metadata.vehicleId,
                        routeId: notifVal.metadata.routeId,
                        fromNotification: true,
                      },
                    });
                  }
                  props.onHide();
                }}
              >
                <div className="d-flex ">
                  <div className="symbol symbol-35px m-2">
                    <span className={clsx('symbol-label', `bg-white`)}>
                      <img
                        src={notifVal.image}
                        alt="check green"
                        height={40}
                        width={40}
                      />
                    </span>
                  </div>
                  <div className="mb-0 mx-2">
                    <p className="fs-16 text-dark fw-500 mb-0">
                      {notifVal.message}
                    </p>
                    <div className="text-gray fw-500 fs-14">
                      {Method.convertDateToDDMMYYYY(notifVal._createdAt)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom"></div>
            </>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center mh-375px h-200px">
            <div className="text-dark fw-500 fs-16">No Data Available</div>
          </div>
        )}
      </div>
    </div>
  );
};
export { HeaderNotificationsMenu };
