import { Button, Card, Col, Row } from 'react-bootstrap';
import ThreeDot from '../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import MarkPaid from '../../modals/mark-paid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Add,
  AdvertisementCompleted,
  AdvertisementOngoing,
  AdvertisementUnpaid,
  AdvertisementUpcoming,
  Advertising,
  Edit,
  FinanceManager,
  HomePage,
  SingleProduct,
} from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { advertisements } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
const AdvertisingDetails = () => {
  const { currentUser } = useAuth();
  const { state }: any = useLocation();
  const [advertisement, setAdvertisement] = useState<any>();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [showMarkPaid, setShowMarkPaid] = useState(false);
  useEffect(() => {
    (async () => {
      await fetchAdvertisementData(state.id);
    })();
  }, []);
  const fetchAdvertisementData = async (id: string) => {
    setFetchLoading(true);
    let apiService = new APICallService(advertisements.details, id,'','','','',Advertising);
    let response = await apiService.callAPI();
    if (response) {
      setAdvertisement(response);
    }
    setFetchLoading(false);
  };
  return (
    <div className="p-9">
      {/* Modals */}
      <>
        {showMarkPaid ? (
          <MarkPaid
            show={showMarkPaid}
            advertisement={advertisement}
            onHide={(advertisement: any) => {
              setAdvertisement(advertisement);
              setShowMarkPaid(false);
            }}
            onClose={() => {
              setShowMarkPaid(false);
            }}
            moduleName ={Advertising}
          />
        ) : (
          <></>
        )}
      </>
      <Row className="mb-7 align-items-center">
        <Col xs>
          <h1 className="fs-22 fw-bolder mb-md-0">Advertising details</h1>
        </Col>
        {advertisement ? (
          <>
            {state.status === AdvertisementCompleted ? (
              // <Col xs="auto">
              //   <Button
              //     variant="primary"
              //     className="min-h-lg-50px"
              //     onClick={() => {
              //       setShowMarkPaid(true);
              //     }}
              //   >
              //     Mark as paid
              //   </Button>
              //   <Button variant="" className="btn btn-flush">
              //     <img width={47} height={47} src={ThreeDot} alt="" />
              //   </Button>
              // </Col>
              <>
                {advertisement.payment.status === AdvertisementUnpaid ? (
                  <Col xs="auto">
                    {Method.hasPermission(Advertising, Add, currentUser) ? (
                    <Button
                      variant="primary"
                      className="min-h-lg-50px"
                      onClick={() => {
                        setShowMarkPaid(true);
                      }}
                    >
                      Mark as paid
                    </Button>
                    ) : (
                      <></>
                    )}
                    {/* <Button
                      variant=""
                      className="btn btn-flush"
                    >
                      <img
                        width={47}
                        height={47}
                        src={ThreeDot}
                        alt=""
                      />
                    </Button> */}
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {state.status === AdvertisementOngoing ? (
              <Col xs="auto">
                <span className="text-gray fs-18 fw-700 me-3">
                  Cost per day:TSh{' '}
                  {Method.getGeneralizedAmount(advertisement.amount)}
                </span>
                {currentUser?.role.some(
                  (obj: any) => obj.type === FinanceManager
                ) ? (
                  <></>
                ) : (
                  <></>
                  // <Button
                  //   variant=""
                  //   className="btn btn-flush"
                  // >
                  //   <img
                  //     width={47}
                  //     height={47}
                  //     src={ThreeDot}
                  //     alt=""
                  //   />
                  // </Button>
                )}
              </Col>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Row>
      {fetchLoading ? (
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoading} />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {advertisement ? (
            <Row>
              <Col sm={12}>
                <Card className="border border-custom-color mb-5 mb-xl-10">
                  <Card.Body className="pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-9 mb-4">
                        <div className="symbol symbol-150px symbol-lg-200px symbol-fixed border position-relative">
                          <div className="image-input d-flex flex-center rounded w-lg-280px h-lg-160px">
                            <div
                              className="image-input-wrapper shadow-none bgi-contain bgi-position-center w-lg-100 h-lg-100"
                              style={{
                                backgroundImage: `url(${advertisement.image})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-wrap flex-sm-row align-items-center">
                              <h3 className="text-black fs-22 fw-700">
                                {' '}
                                {Method.convertDateToDDMMYYYY(
                                  advertisement.startDate
                                ) +
                                  ' to ' +
                                  Method.convertDateToDDMMYYYY(
                                    advertisement.endDate
                                  )}{' '}
                              </h3>
                              {state.status === AdvertisementUpcoming ? (
                                <span className="badge badge-light-warning border-r4px p-3 fs-14 fw-600 mb-1 text-dark ms-3">
                                  Upcoming
                                </span>
                              ) : (
                                <></>
                              )}
                              {state.status === AdvertisementOngoing ? (
                                <span className="badge badge-light-success border-r4px p-3 fs-14 fw-600 mb-1 text-dark ms-3">
                                  Ongoing
                                </span>
                              ) : (
                                <></>
                              )}
                              {state.status === AdvertisementCompleted ? (
                                <span className="badge badge-light border-r4px p-3 fs-14 fw-600 mb-1 text-dark ms-3">
                                  Completed
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="flex flex-column mb-5">
                              <span className="fs-16 fw-500">
                                Seller: {advertisement.business.ownerName}
                              </span>
                            </div>
                            <div className="d-flex flex-wrap flex-stack">
                              <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex flex-wrap">
                                  <div className="bg-light border rounded min-w-125px py-3 px-4 me-5 mb-5">
                                    <div className="d-flex align-items-center">
                                      <div className="fs-22 fw-bolder">
                                        {advertisement.placement === HomePage
                                          ? 'Home page'
                                          : 'Category page'}
                                      </div>
                                    </div>
                                    <div className="fs-16 fw-500 text-gray">
                                      Ad placement
                                    </div>
                                  </div>
                                  <div className="bg-light border rounded min-w-125px py-3 px-4 me-5 mb-5">
                                    <div className="d-flex align-items-center">
                                      <div className="fs-22 fw-bolder">
                                        Products
                                      </div>
                                    </div>
                                    <div className="fs-16 fw-500 text-gray">
                                      {advertisement.variantCount > 0
                                        ? advertisement.variantCount
                                        : ''}{' '}
                                      (
                                      {advertisement.placement === SingleProduct
                                        ? 'Single'
                                        : 'multiple'}
                                      )
                                    </div>
                                  </div>
                                  {state === AdvertisementOngoing ||
                                  state === AdvertisementCompleted ? (
                                    <>
                                      <div className="bg-light border rounded min-w-125px py-3 px-4 me-5 mb-5">
                                        <div className="d-flex align-items-center">
                                          <div className="fs-22 fw-bolder">
                                            {advertisement.impressions}
                                          </div>
                                        </div>
                                        <div className="fs-16 fw-500 text-gray">
                                          Total impressions
                                        </div>
                                      </div>
                                      <div className="bg-light border rounded min-w-125px py-3 px-4 me-5 mb-5">
                                        <div className="d-flex align-items-center">
                                          <div className="fs-22 fw-bolder">
                                            {advertisement.clicks}
                                          </div>
                                        </div>
                                        <div className="fs-16 fw-500 text-gray">
                                          Total clicks
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="border mb-8">
                  <Card.Header className="bg-light align-items-center">
                    <h3 className="fs-22 fw-bolder mb-0">
                      List of added products
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-5">
                      {advertisement.variants.map((variantVal: any) => {
                        return (
                          <Col
                            xxl={3}
                            lg={4}
                            md={6}
                          >
                            <div className="d-flex align-items-center border border-r8px px-5 py-4">
                              <div className="flex-shrink-0">
                                <div className="symbol symbol-50px border">
                                  <img
                                    src={variantVal.reference.media[0].url}
                                    className="object-fit-contain"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h5 className="fs-18 fw-500 mb-1">
                                  {variantVal.reference.product.title.replace(
                                    /\s*\)\s*/g,
                                    ')'
                                  )}
                                </h5>
                                <span className="fs-15 fw-500 text-gray">
                                  {variantVal.reference.product.category.title},
                                  {
                                    variantVal.reference.product.subCategory
                                      .title
                                  }
                                </span>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
export default AdvertisingDetails;
