import { Modal, Button } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APICallService from '../../api/apiCallService';
import { advertisements } from '../../api/apiEndPoints';
import { advertisementJSON } from '../../api/apiJSON/advertisement';
import { AdvertisementPaid, Cash } from '../../utils/constants';
function MarkPaid(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [advertisment, setAdvertisement] = useState(props.advertisement);
  const handlePay = async (advertisementId: string) => {
    setLoading(true);
    let temp = { ...advertisment };
    let apiService = new APICallService(
      advertisements.payment,
      advertisementJSON.payAdvertisement({ paymentMethod: Cash }),
      {
        id: advertisementId,
      },'','','',props.moduleName
    );
    let response: any = await apiService.callAPI();
    if (response) {
      temp.payment = {
        status: AdvertisementPaid,
        paymentMethod: Cash,
        completedByAdmin: true,
      };
      props.onHide(temp);
    }
    setAdvertisement(temp);
    setLoading(false);
  };
  useEffect(() => {
    setAdvertisement(props.advertisement);
  }, [props.advertisement]);
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onClose}
      dialogClassName="modal-dialog-centered min-w-lg-590px"
      className="border-r10px"
      centered
    >
      <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
        <img
          className="close-inner-top"
          width={40}
          height={40}
          src={CrossSvg}
          alt="closebutton"
          onClick={props.onClose}
        />
        <Modal.Title className="fs-26 fw-bolder mw-lg-425px pt-lg-3">
          Did you receive the cash payment of TSh {advertisment.totalAmount}{' '}
          from the seller?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="justify-content-center mt-2 mb-4 border-top-0">
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            handlePay(advertisment._id);
          }}
        >
          {!loading && (
            <span className="indicator-label fs-16 fw-bolder">
              Yes, I’ve received
            </span>
          )}
          {loading && (
            <span
              className="indicator-progress indicator-label fs-16 fw-600"
              style={{ display: 'block' }}
            >
              Please wait...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default MarkPaid;
