import { Button, Card, Col, FormLabel, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Add,
  AirtelMoney,
  CRDBBank,
  DTBBank,
  Edit,
  HaloPesa,
  NMBBank,
  OrderCard,
  OrderCash,
  OrderMPesa,
  OrdersDelivery,
  OrderTigoPesa,
  PAGE_LIMIT,
  ReceivableReports,
  StanbicBank,
  View,
} from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { buyer } from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import Loader from '../../../Global/loader';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { listReceivablePayouts } from '../../../utils/storeString';
import { getKey, removeKey, setKey } from '../../../Global/history';
import Pagination from '../../../Global/pagination';
import { ElementAnimateUtil } from '../../../_admin/assets/ts/_utils';
import { useAuth } from '../auth';
import PermissionModal from '../../modals/permission';
import { receivableOrderJSON } from '../../../utils/staticJSON';
const ReceivableReport = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [details, setDetails] = useState<any>();
  const [enableSelectAll, setEnableSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<any>(
    getKey(listReceivablePayouts.filterEndDate)
      ? new Date(getKey(listReceivablePayouts.filterEndDate)?.toString() || '')
      : null
  );
  const [startDate, setStartDate] = useState<any>(
    getKey(listReceivablePayouts.filterStartDate)
      ? new Date(
          getKey(listReceivablePayouts.filterStartDate)?.toString() || ''
        )
      : null
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const [page, setPage] = useState<any>(
    getKey(listReceivablePayouts.page) || 1
  );
  const [customerPage, setCustomerPage] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listReceivablePayouts.limit) || PAGE_LIMIT
  );
  const [search, setSearch] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAmount, setTotalAmount] = useState([]);
  const [receivablePayout, setReceivablePayout] = useState(false);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [customerData, setCustomerData] = useState<any>([]);
  const [totalCustomer, setTotalCustomer] = useState<any>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(
    getKey(listReceivablePayouts.filterCustomer) || ''
  );
  const [seletedCustomerData, setSelectedCustomerData] = useState<any>(
    getKey(listReceivablePayouts.filterCustomerData) || null
  );
  const [fetchCustomerLoader, setFetchCustomerLoader] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [orderState, setOrderState] = useState<any>(
    receivableOrderJSON[0].value
  );
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      if (!Method.hasModulePermission(ReceivableReports, currentUser)) {
        return window.history.back();
      }
      await fetchAnalytics(startDate, endDate, selectedCustomer);
      await fetchOrder(
        page,
        pageLimit,
        startDate,
        endDate,
        selectedCustomer,
        orderState
      );
      setDataFetched(true);
      setFetchLoading(false);
      await fetchCustomer(1, 10);
    })();
  }, []);
  useEffect(() => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(
        listReceivablePayouts.scrollPosition
      );
      if (initialScrollPosition) {
        ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
        setDataFetched(false);
      }
      setKey(listReceivablePayouts.filterStartDate, null, false);
      setKey(listReceivablePayouts.filterEndDate, null, false);
      setKey(listReceivablePayouts.filterCustomer, '', false);
      setKey(listReceivablePayouts.filterCustomerData, null, false);
    }
  }, [dataFetched]);
  const fetchOrder = async (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    customer: string,
    state: any
  ) => {
    setLoading(true);
    let params = {
      pageNo: page,
      limit: limit,
      needCount: true,
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      customer: customer,
      status: state,
    };
    let apiService = new APICallService(
      buyer.receivablePayouts,
      params,
      '',
      '',
      '',
      '',
      ReceivableReports
    );
    let response = await apiService.callAPI();
    if (response) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setPaymentData(response.records);
    }
    setLoading(false);
  };
  const fetchAnalytics = async (
    startDate: string,
    endDate: string,
    customer: string
  ) => {
    let params = {
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      customer: customer,
    };
    let apiService = new APICallService(
      buyer.totalPayments,
      params,
      '',
      '',
      '',
      '',
      ReceivableReports
    );
    let response = await apiService.callAPI();
    if (response) {
      setTotalAmount(response);
    } else {
      setTotalAmount([]);
    }
  };
  const fetchCustomer = async (
    page: number,
    limit: number,
    search: string = ''
  ) => {
    setFetchCustomerLoader(true);
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
      state: 1,
    };
    let apiService = new APICallService(
      buyer.listBuyer,
      params,
      '',
      '',
      '',
      '',
      ReceivableReports
    );
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalCustomer(response.total);
      let data: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setCustomerData(data);
    }
    setFetchCustomerLoader(false);
  };
  const onMenuScrollToBottom = async () => {
    if (!(customerData.length === totalCustomer)) {
      let tempPage = customerPage;
      tempPage = tempPage + 1;
      setCustomerPage(tempPage);
      await fetchCustomer(tempPage, PAGE_LIMIT, search);
    }
  };
  const onMenuClose = async () => {
    setSearch('');
  };
  const onMenuOpen = async () => {
    setCustomerPage(1);
    await fetchCustomer(1, PAGE_LIMIT, search);
  };
  const handleInputChange = async (newValue: string) => {
    setCustomerPage(1);
    setSearch(newValue);
    await fetchCustomer(1, PAGE_LIMIT, newValue);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setKey(listReceivablePayouts.page, 1);
    setPage(1);
    if (startDate && endDate) {
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchAnalytics(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedCustomer
      );
      await fetchOrder(
        page,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedCustomer,
        orderState
      );
      setFetchLoading(false);
    }
    if (!startDate && !endDate) {
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchAnalytics('', '', selectedCustomer);
      await fetchOrder(page, pageLimit, '', '', selectedCustomer, orderState);
      setFetchLoading(false);
    }
  };
  const handleCustomerChange = async (event: any) => {
    setKey(listReceivablePayouts.page, 1);
    setPage(1);
    if (event) {
      setSelectedCustomer(event.value);
      const customerData = {
        value: event.value,
        title: event.title,
        image: event.image,
      };
      setSelectedCustomerData(customerData);
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchAnalytics(
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        event.value
      );
      await fetchOrder(
        page,
        pageLimit,
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        event.value,
        orderState
      );
      setFetchLoading(false);
    } else {
      setSelectedCustomer('');
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchAnalytics(
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        ''
      );
      await fetchOrder(
        page,
        pageLimit,
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        '',
        orderState
      );
      setFetchLoading(false);
    }
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listReceivablePayouts.page, val);
    await fetchOrder(
      val,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer,
      orderState
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listReceivablePayouts.page, val + 1);
    await fetchOrder(
      val + 1,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer,
      orderState
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listReceivablePayouts.page, val - 1);
    await fetchOrder(
      val - 1,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer,
      orderState
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listReceivablePayouts.page, 1);
    setKey(listReceivablePayouts.limit, parseInt(event.target.value));
    await fetchOrder(
      1,
      event.target.value,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer,
      orderState
    );
  };
  const handleSelectAll = async () => {
    if (selected) {
      setSelectLoading(true);
      setSelectedId([]);
      setSelectedData([]);
      setSelected(!selected);
      setSelectLoading(false);
    } else {
      setSelectLoading(true);
      setSelected(!selected);
      let params: any = {
        fromDate: startDate ? startDate : '',
        toDate: endDate ? endDate : '',
        customer: selectedCustomer ? selectedCustomer : '',
      };
      let apiService = new APICallService(
        buyer.receivablePayouts,
        params,
        '',
        '',
        '',
        '',
        ReceivableReports
      );
      let response: any = await apiService.callAPI();
      let temp: any = [...selectedId];
      let tempData = [...selectedData];
      if (response.records) {
        response.records.map((val: any) => {
          temp.push(val._id);
          tempData.push(val);
        });
      }
      setSelectedData(tempData);
      setSelectedId(temp);
      setSelectLoading(false);
    }
  };
  const handleChecked = (id: string, data: any) => {
    let temp = [...selectedId];
    let tempData = [...selectedData];
    if (temp.length) {
      if (temp.includes(id)) {
        temp = temp.filter((item) => item != id);
        tempData = tempData.filter((item) => item._id != id);
      } else {
        temp.push(id);
        tempData.push(data);
      }
    } else {
      temp.push(id);
      tempData.push(data);
    }
    setSelectedData(tempData);
    setSelectedId(temp);
    setSelected(temp.length == totalRecords);
  };
  const getTypeLabel = (type: string) => {
    switch (type) {
      case OrderCash.toString():
        return 'Cash';
      case OrderTigoPesa.toString():
        return 'Tigo Pesa';
      case OrderMPesa.toString():
        return 'MPesa';
      case OrderCard.toString():
        return 'Card';
      case DTBBank.toString():
        return 'DTB Bank';
      case CRDBBank.toString():
        return 'CRDB Bank';
      case NMBBank.toString():
        return 'NMB Bank';
      case StanbicBank.toString():
        return 'Stanbic Bank';
      case AirtelMoney.toString():
        return 'Airtel Money';
      case HaloPesa.toString():
        return 'Halo Pesa';
      default:
        return '';
    }
  };
  const handleFilter = async (event: any) => {
    setOrderState(event.value);
    setPage(1);
    await setPageLimit(PAGE_LIMIT);
    setKey(listReceivablePayouts.page, 1);
    setKey(listReceivablePayouts.limit, PAGE_LIMIT);
    // if (event.value == 1) {
    await fetchOrder(
      1,
      PAGE_LIMIT,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer,
      event.value
    );
    // } else {
    //   await fetchOrder(
    //     1,
    //     PAGE_LIMIT,
    //     startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
    //     endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
    //     selectedCustomer
    //   );
    // }
  };
  return (
    <>
      {showPermissionModal && (
        <PermissionModal
          show={showPermissionModal}
          onHide={() => setShowPermissionModal(false)}
          moduleName={'orders & delivery'}
        />
      )}
      <div className="p-9">
        <Row className="g-4">
          <Col md>
            <div className="d-flex align-items-center mt-4">
              <h1 className="fs-22 fw-bolder">Receivable Report</h1>
            </div>
          </Col>
          {orderState == 2 && paymentData.length ? (
            <Col
              md="auto"
              className="ms-2 "
            >
              <div className="d-flex align-items-center mt-2">
                {enableSelectAll ? (
                  <Button
                    variant="light-primary"
                    className="fs-18 fw-600 btn-lg me-4"
                    // onClick={() => setDeliveryRoutesPlanningModal(true)}
                    onClick={() => {
                      setSelectedId([]);
                      setSelected(!selected);
                      setEnableSelectAll(false);
                      setSelectLoading(false);
                      setSelected(false);
                      setSelectedData([]);
                    }}
                    disabled={selectLoading}
                  >
                    Cancel
                  </Button>
                ) : (
                  <></>
                )}
                {selectedId.length ? (
                  <Button
                    variant="primary"
                    className="fs-16 fw-bold btn-lg"
                    onClick={() => {
                      navigate('/customers/receivable-report/collect-payment', {
                        state: {
                          data: selectedData,
                        },
                      });
                      let currentScrollY = window.scrollY;
                      setKey(
                        listReceivablePayouts.scrollPosition,
                        currentScrollY
                      );
                      setKey(
                        listReceivablePayouts.filterStartDate,
                        startDate,
                        false
                      );
                      setKey(
                        listReceivablePayouts.filterEndDate,
                        endDate,
                        false
                      );
                      setKey(
                        listReceivablePayouts.filterCustomer,
                        selectedCustomer,
                        false
                      );
                      setKey(
                        listReceivablePayouts.filterCustomerData,
                        seletedCustomerData,
                        false
                      );
                    }}
                  >
                    Mark as collected for {selectedId.length} order
                  </Button>
                ) : (
                  <>
                    {Method.hasPermission(
                      ReceivableReports,
                      Add,
                      currentUser
                    ) ? (
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => setEnableSelectAll(true)}
                      >
                        Mark as collected
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </Col>
          ) : (
            <></>
          )}
          <Row className="align-items-center mb-7 g-6">
            {orderState == 1 &&
              totalAmount &&
              totalAmount.length > 0 &&
              totalAmount.map((val: any, index: number) => (
                <Col
                  xl={4}
                  md={6}
                  sm={4}
                  key={index}
                >
                  <div className="border-r8px bg-fbeace rounded py-4 px-5">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="fs-18 fw-bolder">
                          {val.charge > 0
                            ? 'TSh ' + Method.getGeneralizedAmount(val.charge)
                            : 0}
                        </span>
                        <span className="fw-medium fs-16">
                          {getTypeLabel(val.paymentMethod.toString())}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
          <Col xs={12}>
            <Card className="bg-light border mb-7">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center mw-lg-515px   position-relative me-lg-4">
                      <FormLabel className="fs-16 fw-500">
                        Filter by customer
                      </FormLabel>
                      <div className="ms-5">
                        <CustomSelect
                          //disabled={fetchCustomerLoader}
                          isLoading={fetchCustomerLoader}
                          isClearable={true}
                          backgroundColor="#ffff"
                          options={
                            customerData &&
                            customerData.map((val: any) => ({
                              label: (
                                <>
                                  <div className="symbol symbol-30px border me-3">
                                    <img
                                      src={val.business.image}
                                      className="object-fit-contain"
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.name}
                                  </span>
                                </>
                              ),
                              title: val.name,
                              value: val._id,
                              image: val.business.image,
                            }))
                          }
                          value={
                            selectedCustomer
                              ? {
                                  label: (
                                    <>
                                      <div className="symbol symbol-30px border me-3">
                                        <img
                                          src={seletedCustomerData.image}
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                      <span className="fs-16 fw-600 text-black mb-0">
                                        {seletedCustomerData.title}
                                      </span>
                                    </>
                                  ),
                                  title: seletedCustomerData.title,
                                  value: seletedCustomerData.value,
                                }
                              : null // Set to null when no match is found
                          }
                          onMenuScrollToBottom={onMenuScrollToBottom}
                          onMenuClose={() => {
                            onMenuClose();
                          }}
                          onMenuOpen={() => {
                            onMenuOpen();
                          }}
                          onInputChange={(newValue: any, { action }: any) => {
                            if (action === 'input-change') {
                              handleInputChange(newValue);
                            }
                          }}
                          onChange={(event: any) => {
                            handleCustomerChange(event);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center mw-lg-515px   position-relative me-lg-4">
                      <FormLabel className="fs-16 fw-500">Filter</FormLabel>
                      <div className="ms-5">
                        <CustomSelect
                          backgroundColor="#ffff"
                          // value={teamMember}
                          default={receivableOrderJSON.find(
                            (option) => option?.value === orderState
                          )}
                          options={receivableOrderJSON}
                          onChange={(event: any) => {
                            handleFilter(event);
                          }}
                          isSearchable={false}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <FormLabel className="fs-16 fw-500">
                        Filter by dates
                      </FormLabel>
                      <div className="ms-5">
                        <CustomDatePicker
                          className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                          onChange={handleChange}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          showFullMonthYearPicker={true}
                          maxDate={new Date()}
                          isClearable={true}
                          inputTextBG="bg-white"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card className="border border-r10px mt-8">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    {enableSelectAll ? (
                      <th
                        className="min-w-200px"
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={1}
                      >
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input h-30px w-30px"
                            type="checkbox"
                            value="1"
                            checked={
                              selectLoading ||
                              (selectedId.length == totalRecords && selected)
                            }
                            onChange={(event: any) => {
                              handleSelectAll();
                            }}
                          />
                          <label className="form-check-label fs-16 fw-600 text-dark">
                            Select all
                          </label>
                        </div>
                      </th>
                    ) : (
                      <th className="min-w-md-175px min-w-200px">
                        Order Date & ID
                      </th>
                    )}
                    <th className="min-w-150px">Customer Name</th>
                    <th className="w-150px">Total Amount</th>
                    <th className="w-250px">Paid Amount</th>
                    <th className="w-350px">Remaining Amount</th>
                    <th className="w-150px"></th>
                  </tr>
                </thead>
                <tbody className="fs-15 fw-600">
                  {fetchLoading || loading ? (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={fetchLoading || loading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {selectLoading ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex justify-content-center text-center align-items-center min-h-160px px-3 fs-20 text-dark fw-600">
                              Please wait while processing...
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {paymentData.length ? (
                            <>
                              {paymentData.map((val: any, index: number) => (
                                <>
                                  <tr key={index}>
                                    {enableSelectAll ? (
                                      <td>
                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                          <input
                                            className="form-check-input h-30px w-30px"
                                            type="checkbox"
                                            value={val._id}
                                            checked={selectedId.includes(
                                              val._id
                                            )}
                                            onChange={() => {
                                              handleChecked(val._id, val);
                                            }}
                                          />
                                          <div className="d-flex align-items-start flex-column ms-2">
                                            <div className="d-flex flex-row">
                                              <span className="text-dark d-block">
                                                {Method.convertDateToDDMMYYYYHHMM(
                                                  val._createdAt,
                                                  '-'
                                                )}
                                              </span>
                                            </div>
                                            <span className="text-gray d-block">
                                              {val.refKey}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="fs-15 fw-500">
                                        <div className="d-flex align-items-start flex-column">
                                          <div className="d-flex flex-row">
                                            <span className="text-dark d-block">
                                              {Method.convertDateToDDMMYYYYHHMM(
                                                val._createdAt,
                                                '-'
                                              )}
                                            </span>
                                          </div>
                                          <span className="text-gray d-block">
                                            {val.refKey}
                                          </span>
                                        </div>
                                      </td>
                                    )}
                                    <td>
                                      {' '}
                                      <span className="fs-15 fw-500 d-block">
                                        {' '}
                                        {val.customer.name}{' '}
                                      </span>
                                    </td>
                                    <td>
                                      {' '}
                                      <span className="fs-15 fw-600 d-block">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.totalCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-600 d-block ">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.receivedCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-600 d-block text-danger">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.remainingCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      {Method.hasPermission(
                                        ReceivableReports,
                                        View,
                                        currentUser
                                      ) &&
                                      Method.hasPermission(
                                        OrdersDelivery,
                                        View,
                                        currentUser
                                      ) ? (
                                        <Button
                                          type="button"
                                          variant="primary"
                                          className="btn-table"
                                          onClick={() => {
                                            let currentScrollY = window.scrollY;
                                            setKey(
                                              listReceivablePayouts.scrollPosition,
                                              currentScrollY
                                            );
                                            setKey(
                                              listReceivablePayouts.filterStartDate,
                                              startDate,
                                              false
                                            );
                                            setKey(
                                              listReceivablePayouts.filterEndDate,
                                              endDate,
                                              false
                                            );
                                            setKey(
                                              listReceivablePayouts.filterCustomer,
                                              selectedCustomer,
                                              false
                                            );
                                            setKey(
                                              listReceivablePayouts.filterCustomerData,
                                              seletedCustomerData,
                                              false
                                            );
                                            navigate('/orders/order-details', {
                                              state: {
                                                id: val._id,
                                                moduleName: ReceivableReports,
                                              },
                                            });
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                      {Method.hasPermission(
                                        ReceivableReports,
                                        View,
                                        currentUser
                                      ) &&
                                      !Method.hasPermission(
                                        OrdersDelivery,
                                        View,
                                        currentUser
                                      ) ? (
                                        <Button
                                          type="button"
                                          variant="primary"
                                          className="btn-table"
                                          onClick={() => {
                                            setShowPermissionModal(true);
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                  No Data found
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoading && paymentData.length ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            pageLimit={pageLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ReceivableReport;
