import { Button, Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_admin/helpers';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { useEffect, useState } from 'react';
import { useAuth } from '../auth';
import { customerService } from '../../../api/apiEndPoints';
import { customerServiceJSON } from '../../../api/apiJSON/customerService';
import APICallService from '../../../api/apiCallService';
import Loader from '../../../Global/loader';
import clsx from 'clsx';
import Pagination from '../../../Global/pagination';
import { CustomerServices, PAGE_LIMIT } from '../../../utils/constants';
import Method from '../../../utils/methods';
const MyTeam = (props: any) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [date, setDate] = useState<any>('');
  const { currentUser, saveCurrentUser } = useAuth();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [team, setTeam] = useState<any>([]);
  const [district, setDistrict] = useState<string>(
    (props && props.districtId) ||
      (state && state.districtId) ||
      (currentUser &&
        currentUser.districts &&
        currentUser.districts[0] &&
        currentUser.districts.find((val: any) => val.flag === true).reference)
  );
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(CustomerServices, currentUser)) {
        return window.history.back();
      }
      setFetchLoading(true);
      let district =
        (props && props.districtId) ||
        (state && state.districtId) ||
        (currentUser &&
          currentUser.districts &&
          currentUser.districts[0] &&
          currentUser.districts.find((val: any) => val.flag === true)
            .reference);
      await fetchMyTeam(page, pageLimit, searchTerm, district, '');
      setFetchLoading(false);
    })();
  }, []);
  const fetchMyTeam = async (
    pageNo: number,
    pageLimit: number,
    search: string,
    districtId: string,
    filterDate: string
  ) => {
    let params = {
      pageNo: pageNo,
      limit: pageLimit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      district: districtId,
      date: filterDate,
    };
    let apiService = new APICallService(
      customerService.myTeam,
      customerServiceJSON.listTeam(params),
      {
        districtId: districtId,
        id:
          props && props.userId
            ? props.userId
            : state && state.userId
            ? state.userId
            : currentUser && currentUser._id,
      },
      '','','',CustomerServices
    );
    let response = await apiService.callAPI();
    if (response) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
      setTeam(response.records);
    }
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    await fetchMyTeam(val, pageLimit, searchTerm, district, date);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchMyTeam(val + 1, pageLimit, searchTerm, district, date);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await fetchMyTeam(val - 1, pageLimit, searchTerm, district, date);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    await fetchMyTeam(1, event.target.value, searchTerm, district, date);
    setLoading(false);
  };
  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    setLoading(true);
    await fetchMyTeam(1, pageLimit, value, district, date);
    setLoading(false);
  };
  const handleDateFilter = async (value: any) => {
    setLoading(true);
    setDate(value);
    await fetchMyTeam(page, pageLimit, searchTerm, district, value);
    setLoading(false);
  };
  const handleDistricts = async (event: any) => {
    setDistrict(event.value);
    let user: any = { ...currentUser };
    for (var i = 0; i < user.districts.length; i++) {
      if (user.districts[i].reference === event.value) {
        user.districts[i].flag = true;
      } else {
        user.districts[i].flag = false;
      }
    }
    saveCurrentUser(user);
    setFetchLoading(true);
    setTeam([]);
    setTotalRecords(0);
    await fetchMyTeam(page, pageLimit, searchTerm, event.value, '');
    setFetchLoading(false);
  };
  return (
    <>
      <div className={props.isAdmin ? '' : 'p-9'}>
        {' '}
        <Row className="align-items-center g-5 mb-7">
          <Col
            xs={12}
            sm
          >
            <h1 className="fs-22 fw-bolder mb-0">My team</h1>
          </Col>
          {(props && props.isAdmin) || (state && state.isAdmin) ? (
            <></>
          ) : (
            <>
              {' '}
              <Col xs="auto">
                <CustomSelect
                  value={currentUser?.districts.map((val: any) => {
                    if (val.reference === district) {
                      return {
                        label: val.name,
                        value: val.reference,
                        title: val.name,
                      };
                    }
                  })}
                  backgroundColor="#f9f9f9"
                  minHeight="50px"
                  minWidth="224px"
                  controlFontSize="1.231rem"
                  onChange={(event: any) => {
                    handleDistricts(event);
                  }}
                  options={currentUser?.districts.map((val: any) => {
                    return {
                      label: val.name,
                      value: val.reference,
                      title: val.name,
                    };
                  })}
                />
              </Col>
            </>
          )}
        </Row>
        {(props && props.isAdmin) || (state && state.isAdmin) ? (
          <></>
        ) : (
          <>
            <Card className="bg-light border">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center min-w-md-317px w-fit-content position-relative me-lg-4">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-3 position-absolute ps-5"
                      />
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-custom bg-white min-h-60px ps-13 text-dark"
                        placeholder="Search by member name…"
                        value={searchTerm}
                        onChange={(event: any) => {
                          handleSearch(event.target.value.trimStart());
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Filter by dates
                      </label>
                      <div className="min-w-lg-250px ms-6">
                        <div className="form-group">
                          <div
                            className="input-group date"
                            id="datetimepicker1"
                          >
                            <input
                              type="date"
                              value={date}
                              disabled={loading}
                              max={new Date().toISOString().slice(0, 10)}
                              className={clsx(
                                'form-control form-control-custom bg-white'
                              )}
                              onChange={(event: any) =>
                                handleDateFilter(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}
        <Card className="border border-r10px mt-6">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                    <th className="min-w-200px">
                      {(props && props.isAdmin) || (state && state.isAdmin)
                        ? 'Service team member'
                        : 'Member name'}
                    </th>
                    <th className="min-w-150px">
                      Onboarded <br /> customers
                    </th>
                    <th className="min-w-150px">Orders placed</th>
                    <th className="min-w-100px">Total sales</th>
                    <th className="min-w-200px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {!fetchLoading ? (
                    <>
                      {team.length ? (
                        <>
                          {team.map((teamVal: any, index: number) => {
                            return (
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-5">
                                      <img
                                        src={teamVal.image}
                                        alt=""
                                        className="object-fit-contain"
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      {teamVal.name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {teamVal.buyers}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">0</span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">TSh 0</span>
                                </td>
                                <td className="text-end">
                                  <Button
                                    size="sm"
                                    className="btn-table"
                                    onClick={() =>
                                      (props && props.isAdmin) ||
                                      (state && state.isAdmin)
                                        ? navigate(
                                            '/customer-service/member-details',
                                            {
                                              state: {
                                                id: teamVal._id,
                                                name: teamVal.name,
                                              },
                                            }
                                          )
                                        : navigate('/my-team/member-details', {
                                            state: {
                                              id: teamVal._id,
                                              name: teamVal.name,
                                            },
                                          })
                                    }
                                  >
                                    View details
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <tr>
                            <td colSpan={5}>
                              <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                No Data found
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <td colSpan={5}>
                        <div className="w-100 d-flex justify-content-center">
                          <Loader loading={fetchLoading} />
                        </div>
                      </td>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoading ? (
          <>
            {team && team.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={(event: any) => {
                  handleCurrentPage(event);
                }}
                handleNextPage={(event: any) => {
                  handleNextPage(event);
                }}
                handlePreviousPage={(event: any) => {
                  handlePreviousPage(event);
                }}
                pageLimit={pageLimit}
                handlePageLimit={(event: any) => {
                  handlePageLimit(event);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default MyTeam;
