/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import { useNavigate } from 'react-router-dom';
import APICallService from '../../../../api/apiCallService';
import { customerService, dashboard } from '../../../../api/apiEndPoints';
import Method from '../../../../utils/methods';
import {
  AllModules,
  CartonWithDozens,
  CartonWithPieces,
  Dashboard,
  Dozen,
  Piece,
} from '../../../../utils/constants';
import Loader from '../../../../Global/loader';
import { error } from '../../../../Global/toast';
import moment from 'moment';
const SellersReport = () => {
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [sellerData, setSellerData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [endDate, setEndDate] = useState<any>(new Date());
  useEffect(() => {
    (async () => {
      await fetchReports(startDate, endDate, selectedDistrict);
      await fetchDistrict(1, 10);
    })();
  }, []);
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',Dashboard);
    let response = await apiService.callAPI();
    let data: any = [];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const fetchReports = async (fromDate: any, toDate: any, district: any) => {
    setLoading(true);
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      // fromDate: '2023-11-01',
      // toDate: '2023-11-30',
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '0',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    let apiService = new APICallService(dashboard.sellerReport, params,'','','','',Dashboard);
    let response = await apiService.callAPI();
    setSellerData(response.records);
    setLoading(false);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startMonth = moment(new Date(startDate)).format('MM');
    const endMonth = moment(new Date(endDate)).format('MM');
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
        } else {
          error(
            'Please select a date range with a minimum one-week difference'
          );
        }
      } else {
        error('Please select a date range within a single month');
      }
    }
  };
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event);
      await fetchReports(startDate, endDate, event);
    } else {
      setSelectedDistrict(undefined);
      await fetchReports(startDate, endDate, undefined);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          <div className="card-title">
            <h2 className="fs-22 fw-bolder">Sellers report</h2>
          </div>
          <div className="card-toolbar">
            <div className="min-w-md-193px">
              <CustomSelect
                backgroundColor="#ffff"
                minHeight="50px"
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
              />
            </div>
            <div className="ms-3">
              <CustomDatePicker
                className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
            <div className="ms-3">
              <Button
                variant="link"
                className="btn-flush text-primary fs-16 fw-bold"
                onClick={() => navigate('/dashboard/top-sellers')}
              >
                View all
              </Button>
            </div>
          </div>
        </Card.Header>
        {/* <Card.Body className="py-0">
                    <div className="d-flex flex-center min-h-140px">
                        <span className="fs-18 fw-medium">No details available for these dates.</span>
                    </div>
                </Card.Body> */}
        <Card.Body className="pt-4">
          <div className="border border-r10px bg-white">
            <div className="table-responsive ">
              <table className="table table-row-bordered table-rounded align-middle gs-7 gy-4 my-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-175px">Business name</th>
                    <th className="min-w-150px">Unit sold</th>
                    <th className="min-w-100px">Total orders</th>
                    <th className="min-w-100px">Total sales</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Loader loading={loading} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {sellerData.length ? (
                        <>
                          {sellerData.slice(0, 5).map((val: any) => {
                            return (
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px me-3">
                                      <img
                                        src={val.business.image}
                                        className=""
                                        alt=""
                                      />
                                    </div>
                                    <span className="fw-600 fs-15">
                                      {val.business.name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="badge badge-light border-r23px">
                                    <span className="fs-15 fw-600 text-dark p-3 d-block">
                                      {val.quantityTypes
                                        .map((item: any) => {
                                          if (item.type === CartonWithDozens) {
                                            return `${item.stockCount} C(D)`;
                                          }
                                          if (item.type === CartonWithPieces) {
                                            return `${item.stockCount} C(P)`;
                                          }
                                          if (item.type === Dozen) {
                                            return `${item.stockCount} D`;
                                          }
                                          if (item.type === Piece) {
                                            return `${item.stockCount} P`;
                                          }
                                          return '';
                                        })
                                        .join(', ')}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {val.totalOrders}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    TSh{' '}
                                    {Method.getGeneralizedAmount(
                                      val.totalSales
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4}>
                              <div className="d-flex justify-content-center">
                                <span className="fs-14 fw-500">
                                  No Data found
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default SellersReport;
