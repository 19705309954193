import moment from 'moment';
import {
  Admin,
  AllPermissions,
  AreaBasedCustomerSegmentationReport,
  BrandWiseSalesPerformanceReport,
  CategoryAndSubCategoryAnalysisReport,
  CommissionAnalysisReport,
  CustomerPurchaseBehaviourReport,
  FrequentCustomerPurchasePatternAnalysisReport,
  GeographicSalesInsightReport,
  InventoryStatusReport,
  ProductSalesPerformanceReport,
  Reports,
  RevenueGenerationReport,
} from './constants';
import {
  AllModules,
  Sellers,
  AllSeller,
  KycVerifications,
  PendingCertificate,
  PaymentToSellers,
  SellersSalesReport,
  Customers,
  AllCustomers,
  RefundsToCustomer,
  ReceivableReports,
  OrdersDelivery,
  RoutePlan,
  Master,
  Categories,
  BusinessType,
  BusinessCertificates,
  Brand,
  TechnicalDetails,
  ProductVariants,
  MasterProduct,
  Driver,
  Vehicle,
  VehicleLoadingArea,
  SellerProductZone,
  FinanceMember,
  RolesAndPermission,
  UserManagement,
  Inventory,
  GoodsWarehouse,
  LowStockLists,
  GoodsRequest,
  ReceivedDirectGood,
  CustomerServices,
  All_Products,
  PlatformFees,
  Advertising,
  ContactInquires,
  CustomNotifications,
  Settings,
  Privacy_Policy,
  TermsConditions,
  AppSettings,
  Dashboard,
  FinanceReports,
  OutWardReports,
  ReturnProductReports,
  DeliveryReports,
} from './constants';
import { AnyMessageParams } from 'yup/lib/types';
const Method = {
  convertDateToDDMMYYYY: (date: string) => {
    return moment(date).local().format('DD/MM/YYYY ');
  },
  convertDateToFormat: (date: any, format: string) => {
    return moment(date).local().format(format);
  },
  convertDateToDDMMYYYYHHMM: (date: string, seperator?: string) => {
    if (!seperator)
      return moment(date).local().format('DD/MM/YYYY HH:mm:ss:SS ');
    else {
      let format = 'DD/MM/YYYY [' + seperator + ']  HH:mm:ss:SS';
      return moment(date).local().format(format);
    }
  },
  convertDateToDDMMYYYYHHMMNotLocal: (date: string, seperator?: string) => {
    console.log(
      moment(date)
        .utc()
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY')
    );
    if (!seperator)
      return moment(date)
        .utc()
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm ');
    else {
      let format = 'DD/MM/YYYY [' + seperator + ']  HH:mm';
      return moment(date)
        .utc()
        .utcOffset(new Date().getTimezoneOffset())
        .format(format);
    }
  },
  convertDateToDDMMYYYYHHMMAMPM: (date: string) => {
    return moment(date).local().format('DD/MM/YYYY hh:mm A');
  },
  convertDateToDDMMYYYYHOURS: (date: string) => {
    return moment(date).local().format('DD/MM/YYYY [,] HH:mm ');
  },
  checkisSameOrAfter: (date1: string, date2: string) => {
    return moment(date2).isSameOrAfter(date1);
  },
  checkIsBefore: (date1: string, date2: string) => {
    return moment(date2).isBefore(date1);
  },
  checkAfter: (date1: string, date2: string) => {
    return moment(date2).isAfter(date1);
  },
  checkAfterTime: (date1: string, date2: string) => {
    return moment(date2).isAfter(date1, 'minutes');
  },
  checkSameDate: (date1: string, date2: string) => {
    return moment(date2).isSame(date1, 'date');
  },
  getMonth: (date: string) => {
    return moment(date).format('MM');
  },
  previousMonthStartDate: (date: string, format: string) => {
    return moment(date)
      .subtract(1, 'month')
      .startOf('month')
      .local()
      .format(format);
  },
  previousMonthEndDate: (date: string, format: string) => {
    return moment(date)
      .subtract(1, 'month')
      .endOf('month')
      .local()
      .format(format);
  },
  convertToPreviousMonth: (date: string, format: string) => {
    return moment(date).subtract(1, 'month').local().format(format);
  },
  getMonthV2: (date: string, format: any) => {
    return moment(moment(date).month().toString()).format(format);
  },
  getYear: (date: string) => {
    return moment(date).format('YYYY');
  },
  dayDifference: (date1: string, date2: string) => {
    return moment(date2).diff(date1, 'days');
  },
  currentLocalDateTime: () => {
    return moment().format();
  },
  getDateBeforeDays: (currentDate: any, days: any, format: any) => {
    return moment(currentDate.toString(), format)
      .subtract(parseInt(days), 'days')
      .format(format);
  },
  getTodayDate: (format: string) => {
    return moment().format(format);
  },
  // hasPermission: (module: number, permission: number, currentUser: any) => {
  //   if (!currentUser?.userType) return true;
  //   // if (currentUser?.userType === Admin) return true;
  //   const permissionsArray =
  //     currentUser?.roleAndPermission &&
  //     currentUser?.roleAndPermission.permissions?.find(
  //       (item: any) => item.module === module
  //     )?.permissions;
  //   if (permissionsArray && permissionsArray.length) {
  //     return permissionsArray
  //       ? permissionsArray.includes(AllPermissions) ||
  //           permissionsArray.includes(permission)
  //       : true;
  //   } else {
  //     return false;
  //   }
  // },
  // hasPermission: (module: number, permission: number, currentUser: any): boolean => {
  //   // if (!currentUser?.userType) return true;
  //   // if (currentUser?.userType === Admin) return true;
  //   if (!currentUser?.roleAndPermission?.permissions) return false;
  //   const hasAllModules = currentUser.roleAndPermission.permissions.some(
  //     (item: AnyMessageParams) => item.module === AllModules
  //   );
  //   if (hasAllModules) {
  //     return true;
  //   }
  //   const modulePermissions = currentUser.roleAndPermission.permissions.find(
  //     (item: any) => item.module === module
  //   );
  //   if (!modulePermissions?.permissions?.length) {
  //     return false;
  //   }
  //   return (
  //     modulePermissions.permissions.includes(AllPermissions) ||
  //     modulePermissions.permissions.includes(permission)
  //   );
  // },
  hasPermission: (
    module: number,
    permission: number,
    currentUser: any
  ): boolean => {
    console.log(currentUser);
    if (currentUser.role && currentUser.role.length) {
      return true;
    } else {
      if (!currentUser?.roleAndPermission?.permissions) return false;
      const hasAllModules = currentUser.roleAndPermission.permissions.some(
        (item: any) => item.module === AllModules
      );
      if (hasAllModules) {
        const allModulePermissions =
          currentUser.roleAndPermission.permissions.find(
            (item: any) => item.module === AllModules
          );
        return true;
      }
      const modulePermissions = currentUser.roleAndPermission.permissions.find(
        (item: any) => item.module === module
      );
      if (!modulePermissions?.permissions?.length) {
        return false;
      }
      return modulePermissions.permissions.includes(permission);
    }
  },
  hasModulePermission: (module: number, currentUser: any) => {
    const allModules: any = [
      AllModules,
      Sellers,
      AllSeller,
      KycVerifications,
      PendingCertificate,
      PaymentToSellers,
      SellersSalesReport,
      Customers,
      AllCustomers,
      RefundsToCustomer,
      ReceivableReports,
      OrdersDelivery,
      RoutePlan,
      Master,
      Categories,
      BusinessType,
      BusinessCertificates,
      Brand,
      TechnicalDetails,
      ProductVariants,
      MasterProduct,
      Driver,
      Vehicle,
      VehicleLoadingArea,
      SellerProductZone,
      FinanceMember,
      RolesAndPermission,
      UserManagement,
      Inventory,
      GoodsWarehouse,
      LowStockLists,
      GoodsRequest,
      ReceivedDirectGood,
      CustomerServices,
      All_Products,
      PlatformFees,
      Advertising,
      ContactInquires,
      CustomNotifications,
      Settings,
      Privacy_Policy,
      TermsConditions,
      AppSettings,
      Dashboard,
      FinanceReports,
      OutWardReports,
      ReturnProductReports,
      DeliveryReports,
      Reports,
    ];
    // const permissionModules =
    //   currentUser?.roleAndPermission &&
    //   currentUser?.roleAndPermission.permissions.length > 0
    //     ? currentUser?.roleAndPermission.permissions.permissions.includes(AllModules)
    //       ? allModules
    //       : currentUser.roleAndPermission.permissions.map(
    //           (item: any) => item.module
    //         )
    //     : allModules;
    const permissionModules =
      currentUser?.roleAndPermission &&
      currentUser?.roleAndPermission.permissions.length > 0
        ? currentUser.roleAndPermission.permissions.some(
            (item: any) => item.module === AllModules
          )
          ? allModules
          : currentUser.roleAndPermission.permissions.map(
              (item: any) => item.module
            )
        : allModules;
    return permissionModules.includes(module);
  },
  getFirstAuthorizedRoute: (currentUser: any): string => {
    const routePermissions = [
      { path: '/dashboard', permission: Dashboard },
      { path: '/reports', permission: Reports },
      { path: '/reports', permission: CustomerPurchaseBehaviourReport },
      { path: '/reports', permission: GeographicSalesInsightReport },
      { path: '/reports', permission: AreaBasedCustomerSegmentationReport },
      {
        path: '/reports',
        permission: FrequentCustomerPurchasePatternAnalysisReport,
      },
      { path: '/reports', permission: ProductSalesPerformanceReport },
      { path: '/reports', permission: BrandWiseSalesPerformanceReport },
      { path: '/reports', permission: CategoryAndSubCategoryAnalysisReport },
      { path: '/reports', permission: RevenueGenerationReport },
      { path: '/reports', permission: CommissionAnalysisReport },
      { path: '/reports', permission: InventoryStatusReport },
      { path: '/sellers/list-seller', permission: AllSeller },
      { path: '/sellers/kyc-verification', permission: KycVerifications },
      { path: '/sellers/pending-certificates', permission: PendingCertificate },
      { path: '/sellers/payments-to-sellers', permission: PaymentToSellers },
      { path: '/sellers/sales-report', permission: SellersSalesReport },
      { path: '/customers', permission: AllCustomers },
      {
        path: '/customers/refunds-to-customers',
        permission: RefundsToCustomer,
      },
      { path: '/customers/receivable-report', permission: ReceivableReports },
      { path: '/finance-reports/outward-report', permission: OutWardReports },
      {
        path: '/finance-reports/return-product-report',
        permission: ReturnProductReports,
      },
      { path: '/finance-reports/delivery-report', permission: DeliveryReports },
      { path: '/orders', permission: OrdersDelivery },
      { path: '/master/category', permission: Categories },
      { path: '/master/business-types', permission: BusinessType },
      {
        path: '/master/business-certificates',
        permission: BusinessCertificates,
      },
      { path: '/master/brands', permission: Brand },
      { path: '/master/technical-details', permission: TechnicalDetails },
      { path: '/master/product-variants', permission: ProductVariants },
      { path: '/products/master-products', permission: MasterProduct },
      { path: '/master/drivers', permission: Driver },
      { path: '/master/vehicles', permission: Vehicle },
      { path: '/master/loading-areas', permission: VehicleLoadingArea },
      { path: '/master/seller-product-zone', permission: SellerProductZone },
      { path: '/master/finance-members', permission: FinanceMember },
      { path: '/master/roles-permissions', permission: RolesAndPermission },
      { path: '/master/user-management', permission: UserManagement },
      { path: '/inventory/goods-in-warehouse', permission: GoodsWarehouse },
      { path: '/inventory/low-stock-list', permission: LowStockLists },
      { path: '/inventory/goods-requests', permission: GoodsRequest },
      {
        path: '/inventory/received-direct-goods',
        permission: ReceivedDirectGood,
      },
      { path: '/customer-service', permission: CustomerServices },
      { path: '/all-products', permission: All_Products },
      { path: '/platform-fees/fees', permission: PlatformFees },
      { path: '/advertisement/view-advertisement', permission: Advertising },
      { path: '/contact-inquiries/inquiries', permission: ContactInquires },
      { path: '/custom-notification', permission: CustomNotifications },
      { path: '/settings/privacy-policy', permission: Privacy_Policy },
      { path: '/settings/terms-condition', permission: TermsConditions },
      { path: '/settings/app-settings', permission: AppSettings },
      { path: '/reports/index', permission: Reports },
    ];
    const firstAllowedRoute = routePermissions.find((route) =>
      Method.hasModulePermission(route.permission, currentUser)
    );
    return firstAllowedRoute?.path || '';
  },
  getGeneralizedAmount: (amount: any) => {
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
  },
  formatNumberWithSuffix: (number: any = 0) => {
    const nonillion = 1e30;
    const octillion = 1e27;
    const septillion = 1e24;
    const sextillion = 1e21;
    const quintillion = 1e18;
    const quadrillion = 1e15;
    const trillion = 1e12;
    const billion = 1e9;
    const million = 1e6;
    const thousand = 1e3;
    let returnObj = {
      updatedNumber: number,
      suffixStr: '',
    };
    if (number >= nonillion) {
      returnObj.updatedNumber = number / nonillion;
      returnObj.suffixStr = 'No';
    } else if (number >= octillion) {
      returnObj.updatedNumber = number / octillion;
      returnObj.suffixStr = 'Oc';
    } else if (number >= septillion) {
      returnObj.updatedNumber = number / septillion;
      returnObj.suffixStr = 'Sp';
    } else if (number >= sextillion) {
      returnObj.updatedNumber = number / sextillion;
      returnObj.suffixStr = 'Sx';
    } else if (number >= quintillion) {
      returnObj.updatedNumber = number / quintillion;
      returnObj.suffixStr = 'Qn';
    } else if (number >= quadrillion) {
      returnObj.updatedNumber = number / quadrillion;
      returnObj.suffixStr = 'Q';
    } else if (number >= trillion) {
      returnObj.updatedNumber = number / trillion;
      returnObj.suffixStr = 'T';
    } else if (number >= billion) {
      returnObj.updatedNumber = number / billion;
      returnObj.suffixStr = 'B';
    } else if (number >= million) {
      returnObj.updatedNumber = number / million;
      returnObj.suffixStr = 'M';
    } else if (number >= thousand) {
      returnObj.updatedNumber = number / thousand;
      returnObj.suffixStr = 'K';
    }
    return returnObj;
  },
  replaceSpace: (val: string) => {
    return val.replace(/\s*\)\s*/g, ')');
  },
  populateMissingDates: (dataObject: any, startDate?: any, endDate?: any) => {
    dataObject = dataObject.sort((a: any, b: any) =>
      a.date.localeCompare(b.date)
    );
    let lowestIndex = 0; // Initialize with the first index as the lowest
    let lowestDate = dataObject[0].date; // Initialize with the first date as the lowest
    let highestIndex = 0; // Initialize with the first index as the highest
    let highestDate = dataObject[0].date; // Initialize with the first date as the highest
    for (let i = 1; i < dataObject.length; i++) {
      if (dataObject[i].date < lowestDate) {
        lowestIndex = i;
        lowestDate = dataObject[i].date;
      }
      if (dataObject[i].date > highestDate) {
        highestIndex = i;
        highestDate = dataObject[i].date;
      }
    }
    let temp = [...dataObject];
    const allDates = Array.from(new Set(temp.map((entry: any) => entry.date)));
    // Get the unique months from the dataObject
    const uniqueMonths = Array.from(
      new Set(allDates.map((date: any) => date.slice(0, 7)))
    );
    // Populate missing dates for each month in the dataObject with zeros
    uniqueMonths.forEach((month) => {
      const monthDates = allDates.filter((date: any) => date.startsWith(month));
      let Start = moment(lowestDate).format('DD');
      let end = moment(highestDate).format('DD');
      for (let i = parseInt(startDate); i <= parseInt(endDate); i++) {
        let monthString = '';
        switch (parseInt(month.slice(5, 7))) {
          case 1:
            monthString = 'Jan';
            break;
          case 2:
            monthString = 'Feb';
            break;
          case 3:
            monthString = 'Mar';
            break;
          case 4:
            monthString = 'Apr';
            break;
          case 5:
            monthString = 'May';
            break;
          case 6:
            monthString = 'Jun';
            break;
          case 7:
            monthString = 'July';
            break;
          case 8:
            monthString = 'Aug';
            break;
          case 9:
            monthString = 'Sep';
            break;
          case 10:
            monthString = 'Oct';
            break;
          case 11:
            monthString = 'Nov';
            break;
          case 12:
            monthString = 'Dec';
            break;
        }
        const day = i < 10 ? `0${i}` : `${i}`;
        const date = `${month}-${day}`;
        const dateString = `${monthString} ${day}`;
        if (!monthDates.includes(date)) {
          temp.push({
            date: date,
            totalSales: 0,
            totalOrders: 0,
            fullDate: dateString,
            customers: 0,
            sellers: 0,
          });
        } else {
          const existingEntryIndex = temp.findIndex(
            (entry: any) => entry.date == date
          );
          if (existingEntryIndex !== -1) {
            // Add fullDate if it's not already present
            if (!temp[existingEntryIndex].fullDate) {
              temp[existingEntryIndex].fullDate = dateString;
            }
          }
        }
      }
    });
    // Sort dataObject based on dates
    return temp.sort((a: any, b: any) => a.date.localeCompare(b.date));
  },
  decimalHoursToHoursMinutes(decimalHours: number) {
    const hours = Math.floor(decimalHours / 60);
    // Get the remaining minutes part
    const minutes = Math.round(decimalHours % 60);
    return `${hours} hrs and ${minutes} mins`;
  },
  decimalHoursToHoursMinutesColon(decimalHours: number) {
    const hours = Math.floor(decimalHours / 60);
    // Get the remaining minutes part
    const minutes = Math.round(decimalHours % 60);
    return `${hours == 0 ? `0${hours}` : hours}:${
      minutes == 0 ? `0${minutes}` : minutes
    } `;
  },
  metersToKilometersMeters(meters: number) {
    const kilometers = Math.floor(meters / 1000);
    const remainingMeters = meters % 1000;
    return `${kilometers} km ${remainingMeters.toFixed(0)} m`;
  },
  trimLeadingZeros(stringValue: any) {
    if (stringValue) {
      try {
        stringValue = stringValue.toString().replace(/^0+/, '');
      } catch (error) {
        throw error;
      }
    }
    return stringValue ? stringValue : 0;
  },
};
export default Method;
