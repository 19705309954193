import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import SelectReportType from './selectReportType';
const index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
        <Route
          index
          element={<Navigate to="/reports/" />}
        />
        <Route
          path="/index"
          element={
            <>
              <SelectReportType />
            </>
          }
        />
      </Route>
    </Routes>
  );
};
export default index;
