import { useEffect, useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import defaultImg from '../../../_admin/assets/media/product/default.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import TabsOrders from './tabs/tabs-orders';
import EditBusinessDetails from './tabs/edit-business-details';
import ViewBusinessDetails from './tabs/view-business-details';
import Email from '../../../_admin/assets/media/svg_uTrade/email.svg';
import PhoneCall from '../../../_admin/assets/media/svg_uTrade/phone-call.svg';
import APICallService from '../../../api/apiCallService';
import { buyer, customerService } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import { IGetCustomerProfile } from '../../../types/responseIndex';
import TabCustomerDashboard from './tabs/tab-customer-dashboard';
import ThreeDots from '../../../_admin/assets/media/svg_uTrade/three-dot.svg';
import TabRefunds from './tabs/tabs-refunds';
import Method from '../../../utils/methods';
import { customerJSON } from '../../../api/apiJSON/customer';
import { fileValidation } from '../../../Global/fileValidation';
import { AllCustomers, APIkey, Edit } from '../../../utils/constants';
import edit from '../../../_admin/assets/media/edit-round-large.png';
import { success } from '../../../Global/toast';
import DeleteModal from '../../modals/delete';
import { listCustomerProfile } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import { useAuth } from '../auth';
const CustomerProfile = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [editData, setEditData] = useState<IGetCustomerProfile | undefined>(
    undefined
  );
  const [profileData, setProfileData] = useState<
    IGetCustomerProfile | undefined
  >(undefined);
  const [customerData, setCustomerData] = useState<{
    name: string;
    email: string;
    businessName: string;
    district: string;
    districtName: string;
    address: string;
    vatNumber: string;
    tinNumber: any;
    image: any;
    imageReader: any;
    types: any;
    phoneNumber: string;
    phoneCountry: string;
    lat: string;
    lng: string;
    deliveryAddresses: any;
  }>({
    name: '',
    email: '',
    address: '',
    vatNumber: '',
    tinNumber: '',
    image: '',
    businessName: '',
    imageReader: {},
    types: {},
    phoneNumber: '',
    phoneCountry: '+255',
    lat: '1',
    district: '',
    districtName: '',
    lng: '1',
    deliveryAddresses: [],
  });
  const [customerValidation, setCustomerValidation] = useState<{
    name: boolean;
    businessName: boolean;
    address: boolean;
    types: boolean;
    phoneNumber: boolean;
  }>({
    name: false,
    address: false,
    businessName: false,
    types: false,
    phoneNumber: false,
  });
  const [editCustomerProfile, setEditCustomerProfile] = useState(false);
  const [tab, setTab] = useState([
    { name: 'Dashboard', content: <TabCustomerDashboard />, value: '1' },
    { name: 'Business details', content: <ViewBusinessDetails />, value: '2' },
    { name: 'Orders', content: <TabsOrders />, value: '3' },
    { name: 'Refunds', content: <TabRefunds />, value: '4' },
  ]);
  const [districts, setDistricts] = useState([]);
  // const [currentTab, setCurrentTab] = useState({
  //   name: 'Business details',
  //   content: <ViewBusinessDetails />,
  //   value: '2',
  // });
  const [currentTab, setCurrentTab] = useState<any>(() => {
    const storedTabValue = getKey(listCustomerProfile.currentTab);
    const matchingTab: any = tab.find(tab => tab.value == storedTabValue)
    console.log(matchingTab);
    return matchingTab || tab[1];
  });
  // const handleSelectTab = (tab: any) => {
  //   if (tab.value === '1') {
  //     setEditCustomerProfile(false);
  //     setCurrentTab({
  //       name: 'Dashboard',
  //       content: <TabCustomerDashboard customerId={location.state} />,
  //       value: '1',
  //     });
  //   } else if (tab.value === '2') {
  //     setCurrentTab({
  //       name: 'Business details',
  //       content: editCustomerProfile ? (
  //         <EditBusinessDetails
  //           editData={editData}
  //           customerData={customerData}
  //           customerValidation={customerValidation}
  //           handleBasicDetailsChange={handleBasicDetailsChange}
  //           handleBusinessTypesSelect={handleBusinessTypesSelect}
  //           handleImageChange={handleImageChange}
  //           handleAddCustomer={handleAddCustomer}
  //           handleAddressChange={handleAddressChange}
  //           handleOnKeyPress={handleOnKeyPress}
  //         />
  //       ) : (
  //         <ViewBusinessDetails profileData={profileData} />
  //       ),
  //       value: '2',
  //     });
  //   } else if (tab.value === '4') {
  //     setEditCustomerProfile(false);
  //     setCurrentTab({ name: 'Refunds', content: <TabRefunds />, value: '4' });
  //   } else {
  //     setEditCustomerProfile(false);
  //     setCurrentTab(tab);
  //   }
  //   setKey(listCustomerProfile.currentTab, tab.value, false);
  // };
  const createTabs = () => {
    const localTab = tab;
    let current = currentTab;
    const allTabs = localTab.map((tab) => {
      return (
        <li className="nav-item">
          <Link
            to={'/'}
            className={
              current.value === tab.value
                ? 'nav-link active text-active-dark'
                : 'nav-link text-hover-dark'
            }
            data-bs-toggle="tab"
            onClick={() => handleSelectTab(tab)}
          >
            {tab.name}
          </Link>
        </li>
      );
    });
    return allTabs;
  };
  const fetchDistrict = async () => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',AllCustomers);
    let response = await apiService.callAPI();
    let data: any = [...districts];
    setDistricts(response.records);
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchProfileData();
      fetchDistrict();
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    if (editCustomerProfile) {
      const businessDetailsTab = tab.find(t => t.value === '2');
      if (businessDetailsTab) {
        setCurrentTab({
          ...businessDetailsTab,
          content: getTabContent('2')
        });
        setKey(listCustomerProfile.currentTab, '2', false);
      }
    }
  }, [editCustomerProfile]);
  // useEffect(() => {
  //   setCurrentTab({
  //     name: 'Business details',
  //     content: editCustomerProfile ? (
  //       <EditBusinessDetails
  //         editData={editData}
  //         customerData={customerData}
  //         customerValidation={customerValidation}
  //         handleBasicDetailsChange={handleBasicDetailsChange}
  //         handleBusinessTypesSelect={handleBusinessTypesSelect}
  //         handleImageChange={handleImageChange}
  //         handleAddCustomer={handleAddCustomer}
  //         handleAddressChange={handleAddressChange}
  //         handleOnKeyPress={handleOnKeyPress}
  //       />
  //     ) : (
  //       <ViewBusinessDetails profileData={profileData} />
  //     ),
  //     value: '2',
  //   });
  // }, [editCustomerProfile]);
  // useEffect(() => {
  //   setCurrentTab({
  //     name: 'Business details',
  //     content: editCustomerProfile ? (
  //       <EditBusinessDetails
  //         editData={editData}
  //         customerData={customerData}
  //         customerValidation={customerValidation}
  //         handleBasicDetailsChange={handleBasicDetailsChange}
  //         handleBusinessTypesSelect={handleBusinessTypesSelect}
  //         handleImageChange={handleImageChange}
  //         handleAddCustomer={handleAddCustomer}
  //         handleAddressChange={handleAddressChange}
  //         handleOnKeyPress={handleOnKeyPress}
  //       />
  //     ) : (
  //       <ViewBusinessDetails profileData={profileData} />
  //     ),
  //     value: '2',
  //   });
  // }, [customerData]);
  // useEffect(() => {
  //   setCurrentTab({
  //     name: 'Business details',
  //     content: editCustomerProfile ? (
  //       <EditBusinessDetails
  //         editData={editData}
  //         customerData={customerData}
  //         customerValidation={customerValidation}
  //         handleBasicDetailsChange={handleBasicDetailsChange}
  //         handleBusinessTypesSelect={handleBusinessTypesSelect}
  //         handleImageChange={handleImageChange}
  //         handleAddCustomer={handleAddCustomer}
  //         handleAddressChange={handleAddressChange}
  //         handleOnKeyPress={handleOnKeyPress}
  //       />
  //     ) : (
  //       <ViewBusinessDetails profileData={profileData} />
  //     ),
  //     value: '2',
  //   });
  // }, [customerValidation]);
  const fetchProfileData = async () => {
    const customerId = location.state? location.state : localStorage.getItem('CustomerId');
    let apiService = new APICallService(buyer.details, customerId,'','','','',AllCustomers);
    let response = await apiService.callAPI();
    if (response) {
      setProfileData(JSON.parse(JSON.stringify(response)));
      setEditData(JSON.parse(JSON.stringify(response)));
      // setCurrentTab({
      //   name: 'Dashboard',
      //   content: <TabCustomerDashboard customerId={location.state} />,
      //   value: '1',
      // });
      setCustomerData({
        name: response.user.name || '',
        email: response.user.email || '',
        address: response.user.business.address || '',
        vatNumber: response.user.business.vatNumber || '',
        tinNumber: response.user.business.tinNumber || '',
        image: response.user.business.image || '',
        businessName: response.user.business.name || '',
        imageReader: {},
        types: response.user.business.types.length
          ? response.user.business.types[0].reference
          : '',
        phoneNumber: response.user.phone || '',
        phoneCountry: '+255',
        lat: response.user.business.lat || '1',
        district: response.user.business.district || '',
        districtName: response.user.business.districtName || '',
        lng: response.user.business.lng || '1',
        deliveryAddresses: response.user.deliveryAddresses || [],
      });
      localStorage.setItem('customer', JSON.stringify(response));
      // setCurrentTab({
      //   name: 'Business details',
      //   content: editCustomerProfile ? (
      //     <EditBusinessDetails editData={editData} />
      //   ) : (
      //     <ViewBusinessDetails profileData={profileData} />
      //   ),
      //   value: '2',
      // });
    }
  };
  const handleBasicDetailsChange = (name: string, value: string) => {
    let temp: any = { ...customerData };
    let tempValidation: any = { ...customerValidation };
    temp[name] = value.trimStart();
    if (Object.keys(tempValidation).includes(name)) {
      tempValidation[name] = false;
      if (!value.length) tempValidation[name] = true;
    }
    setCustomerData(temp);
    setCustomerValidation(tempValidation);
  };
  const handleBusinessTypesSelect = (id: any) => {
    let temp: any = { ...customerData };
    let tempValidation: any = { ...customerValidation };
    temp.types = id;
    tempValidation.types = false;
    console.log('temp', temp);
    setCustomerData(temp);
    setCustomerValidation(tempValidation);
  };
  const handleImageChange = (event: any) => {
    const selectedFiles = event.target.files;
    let temp = { ...customerData };
    if (!selectedFiles) return;
    else {
      if (fileValidation(selectedFiles?.[0])) {
        temp.image = URL.createObjectURL(selectedFiles?.[0]);
        temp.imageReader = selectedFiles?.[0];
      }
    }
    setCustomerData(temp);
  };
  const handleAddCustomer = async () => {
    let temp = { ...customerData };
    let validateTemp = { ...customerValidation };
    if (temp.name === '') {
      validateTemp.name = true;
    }
    if (temp.businessName === '') {
      validateTemp.businessName = true;
    }
    if (temp.phoneNumber === '') {
      validateTemp.phoneNumber = true;
    }
    // if (temp.email === '') {
    //   validateTemp.email = true;
    // }
    if (temp.address === '') {
      validateTemp.address = true;
    }
    if (temp.types === '') {
      validateTemp.types = true;
    }
    if (Object.values(validateTemp).every((val) => val === false)) {
      setSaveLoading(true);
      let temp = { ...customerData };
      temp.types = temp.types._id;
      let apiService = new APICallService(
        buyer.updateProfile,
        customerJSON.addCustomer(temp),
        {
          id: location.state,
        },
        '','','',AllCustomers
      );
      let response = await apiService.callAPI();
      if (response) {
        // navigate('/customers');
        await fetchProfileData();
        setEditCustomerProfile(false);
      }
      setSaveLoading(false);
    }
    setCustomerValidation(validateTemp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) || // A-Z
      (charCode >= 97 && charCode <= 122) || // a-z
      (charCode >= 48 && charCode <= 57) // 0-9
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };
  const handleAddressChange = async (
    value: string,
    lat: string,
    lng: string
  ) => {
    let tempValidation: any = JSON.parse(
      JSON.stringify({ ...customerValidation })
    );
    let temp: any = { ...customerData };
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIkey}`;
    await fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          const results = data.results[0];
          let districtMatched = false;
          // Extract city and district from the address components
          for (const component of results.address_components) {
            if (component.types.includes('administrative_area_level_3')) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes('administrative_area_level_2')
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes('administrative_area_level_1')
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            }
            if (!districtMatched) {
              temp.districtName = '';
              tempValidation.district = true;
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    const matchingObjects: any = districts.filter((obj: any) => {
      // we're checking if 'searchText' is present in the 'name' property
      return obj.name.includes(temp.districtName);
    });
    if (matchingObjects.length) {
      temp.districtName = matchingObjects[0].name;
      temp.district = matchingObjects[0]._id;
    } else {
      temp.districtName = 'UpCountry';
      temp.district = '651d5fa8317c6e0dcdcd8fa1';
    }
    temp['address'] = value.trimStart();
    temp['lat'] = lat;
    temp['lng'] = lng;
    setCustomerData(temp);
    if (!value.trimStart().length) {
      tempValidation.address = true;
    } else {
      tempValidation.address = false;
    }
    setCustomerValidation(tempValidation);
  };
  const onProfileChange = async (event: any) => {
    let temp: any = { ...customerData };
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    else {
      temp.image = URL.createObjectURL(selectedFiles?.[0]);
      temp.imageReader = selectedFiles?.[0];
    }
    setCustomerData(temp);
  };
  const getTabContent = (tabValue: any) => {
    switch (tabValue) {
      case '1':
        return <TabCustomerDashboard customerId={location.state} moduleName={AllCustomers}/>;
      case '2':
        return editCustomerProfile ? (
          <EditBusinessDetails
            editData={editData}
            customerData={customerData}
            customerValidation={customerValidation}
            handleBasicDetailsChange={handleBasicDetailsChange}
            handleBusinessTypesSelect={handleBusinessTypesSelect}
            handleImageChange={handleImageChange}
            handleAddCustomer={handleAddCustomer}
            handleAddressChange={handleAddressChange}
            handleOnKeyPress={handleOnKeyPress}
            moduleName ={AllCustomers}
          />
        ) : (
          <ViewBusinessDetails profileData={profileData} moduleName={AllCustomers}/>
        );
      case '3':
        return <TabsOrders customerId={location.state} moduleName={AllCustomers}/>;
      case '4':
        return <TabRefunds customerId={location.state} moduleName={AllCustomers}/>;
      default:
        return null;
    }
  };
  const currentTabContent = useMemo(() => getTabContent(currentTab.value), [
    currentTab.value,
    editCustomerProfile,
    editData,
    customerData,
    customerValidation,
    profileData
  ]);
  const handleSelectTab = (tab: any) => {
    setEditCustomerProfile(false);
    setCurrentTab({
      ...tab,
      content: getTabContent(tab.value)
    });
    setKey(listCustomerProfile.currentTab, tab.value, false);
  };
  return (
    <>
      <div className="p-9">
        <Row className="mb-7 align-items-center">
          <Col md>
            <h1 className="fs-22 fw-bolder mb-md-0 mb-3">Customer profile</h1>
          </Col>
          {!loading ? (
            <Col md="auto">
              {editCustomerProfile ? (
                <div className="d-flex flex-row">
                  {saveLoading ? (
                    <></>
                  ) : (
                    <>
                      {' '}
                      <Button
                        variant="light-primary"
                        className="me-3"
                        onClick={() => {
                          setEditCustomerProfile(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  <Button
                    variant="primary"
                    className="me-3"
                    onClick={() => {
                      handleAddCustomer();
                    }}
                    disabled={saveLoading}
                  >
                    {!saveLoading && (
                      <span className="indicator-label fs-16 fw-bold">
                        Save changes
                      </span>
                    )}
                    {saveLoading && (
                      <span
                        className="indicator-progress fs-16 fw-bold"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                  {/* <Button
                    variant=""
                    className="btn-flush"
                  >
                    <img
                      src={ThreeDots}
                      alt=""
                    />
                  </Button> */}
                </div>
              ) : (
                <div className="d-flex flex-row">
                  {Method.hasPermission(AllCustomers, Edit, currentUser) ? (
                  <Button
                    variant="light-primary"
                    className="me-3"
                    onClick={() => {
                      setEditCustomerProfile(true);
                    }}
                  >
                    Edit profile details
                  </Button>
                  ) : (
                    <></>
                  )}
                  {/* <Button
                    variant=""
                    className="btn-flush"
                  >
                    <img
                      src={ThreeDots}
                      alt=""
                    />
                  </Button> */}
                </div>
              )}
            </Col>
          ) : (
            <></>
          )}
        </Row>
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <Loader loading={loading} />
            </div>
          </>
        ) : (
          <>
            <Card className="border border-custom-color mb-7">
              <Card.Body className="pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div className="me-9">
                    {/* <div className="symbol symbol-200px symbol-fixed border border-r10px position-relative">
                      <div className="image-input d-flex flex-center rounded w-lg-200px w-150px h-lg-200px h-150px">
                        {profileData?.user.business.image ? (
                          <div
                            className="image-input-wrapper shadow-none bgi-cover bgi-position-center border-r10px w-100 h-100"
                            style={{
                              backgroundImage: `url(${profileData?.user.business.image})`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className="image-input-wrapper shadow-none bgi-cover bgi-position-center border-r10px w-100 h-100"
                            style={{
                              backgroundImage: `url(${defaultImg})`,
                            }}
                          ></div>
                        )}
                      </div>
                    </div> */}
                    <div className="symbol symbol-200px symbol-fixed border border-r10px position-relative">
                      <div className="image-input d-flex flex-center rounded w-lg-200px h-lg-200px">
                        <div
                          className="image-input-wrapper shadow-none bgi-cover bgi-position-center border-r10px w-100 h-100"
                          style={{
                            backgroundImage: `url(${
                              editCustomerProfile
                                ? customerData?.image
                                  ? customerData?.image
                                  : defaultImg
                                : profileData?.user.business.image
                                ? profileData?.user.business.image
                                : defaultImg
                            })`,
                          }}
                        ></div>
                        {editCustomerProfile ? (
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-50px h-50px top-100 bottom-0"
                            data-kt-image-input-action="change"
                            title=""
                          >
                            <img
                              src={edit}
                              height={33}
                              width={33}
                              alt=""
                            />
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => onProfileChange(e)}
                            />
                            <input
                              type="hidden"
                              name="avatar_remove"
                            />
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-column mb-2">
                          <h3 className="text-black fs-22 fw-bolder">
                            {profileData?.user.business.name}
                          </h3>
                          <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                            <div className="d-flex align-items-center text-black fs-16 fw-500 me-5 mb-2">
                              <span className="svg-icon svg-icon-4 me-2">
                                <img
                                  src={PhoneCall}
                                  alt="Phone number"
                                />
                              </span>{' '}
                              {profileData?.user.phoneCountry}{' '}
                              {profileData?.user.phone}
                            </div>
                            {profileData?.user.email ? (
                              <div className="d-flex align-items-center text-black fs-16 fw-500 mb-2">
                                <span className="svg-icon svg-icon-4 me-2">
                                  <img
                                    src={Email}
                                    alt="Email"
                                  />
                                </span>
                                {profileData?.user.email}{' '}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                                    <div className='d-flex justify-content-center align-items-center bg-light-warning p-3 px-4 pills-radius'>
                                                        <span className='bullet bullet-dot bg-warning me-2 h-10px w-10px'></span>
                                                        <span className='fw-600 text-black fs-18'>
                                                            Verification pending
                                                        </span>
                                                    </div>
                                                </div> */}
                        {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                                    <div className='d-flex justify-content-center align-items-center bg-light-danger p-3 px-4 pills-radius'>
                                                        <span className='bullet bullet-dot bg-danger me-2 h-10px w-10px'></span>
                                                        <span className='fw-600 text-black fs-18'>
                                                            More info required: Please upload clear picture of TIN
                                                            Certificate and Business License.
                                                        </span>
                                                    </div>
                                                </div> */}
                        {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                                    <div className='d-flex justify-content-center align-items-center bg-light-success p-3 px-4 pills-radius'>
                                                        <span className='bullet bullet-dot bg-success me-2 h-10px w-10px'></span>
                                                        <span className='fw-600 text-black fs-18'>
                                                            Business details approved
                                                        </span>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                    <Col lg={12}>
                      <div className="d-flex flex-wrap flex-stack">
                        <div className="d-flex flex-column flex-grow-1 pe-8">
                          <div className="d-flex flex-wrap">
                            <div className="bg-light border rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="fs-22 fw-bolder">
                                  {profileData?.user.totalOrders
                                    ? profileData?.user.totalOrders
                                    : 0}
                                </div>
                              </div>
                              <div className="fw-500 fs-16">Total orders</div>
                            </div>
                            <div className="bg-light border rounded min-w-175px py-3 px-4 me-6 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="fs-22 fw-bolder">
                                  {profileData?.user.orderFrequency
                                    ? profileData?.user.orderFrequency
                                    : '0'}{' '}
                                  days
                                </div>
                              </div>
                              <div className="fw-500 fs-16">Average Order</div>
                            </div>
                            <div className="bg-light border rounded min-w-175px py-3 px-4 me-6 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="fs-22 fw-bolder">
                                  TSh{' '}
                                  {profileData?.user.averageOrderValue
                                    ? Method.getGeneralizedAmount(
                                        profileData?.user.averageOrderValue
                                      )
                                    : '0'}
                                </div>
                              </div>
                              <div className="fw-500 fs-16">
                                Average Order value
                              </div>
                            </div>
                            <div className="bg-light border rounded min-w-175px py-3 px-4 me-6 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="fs-22 fw-bolder">
                                  TSh{' '}
                                  {profileData?.user.totalOrderValue
                                    ? Method.getGeneralizedAmount(
                                        profileData?.user.totalOrderValue
                                      )
                                    : '0'}
                                </div>
                              </div>
                              <div className="fw-500 fs-16">
                                Total Order value
                              </div>
                            </div>
                            <div className="bg-light border rounded min-w-175px py-3 px-4 me-6 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="fs-22 fw-bolder">
                                  {profileData?.user.assignedTo
                                    ? profileData?.user.assignedTo.name
                                    : 'NA'}
                                </div>
                              </div>
                              <div className="fw-500 fs-16">
                                Assigned member
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              </Card.Body>
              <Row className="align-items-center px-lg-9 px-6">
                <Col sm>
                  <div className="d-flex h-70px">
                    <ul className="nav nav-stretch nav-line-tabs nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-18 fw-600">
                      {createTabs()}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card>
            <div
              className="tab-content"
              id="myTabContent"
            >
              <div className="tab-pane fade show active">
                <>{currentTabContent}</>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default CustomerProfile;
