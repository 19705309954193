/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
// import ApexCharts, { ApexOptions } from 'apexcharts';
import {
  getCSS,
  getCSSVariableValue,
} from '../../../../_admin/assets/ts/_utils';
import { useThemeMode } from '../../../../_admin/partials';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import { IMAGES } from '../../../../utils/dummyJSON';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import APICallService from '../../../../api/apiCallService';
import { customerService, dashboard } from '../../../../api/apiEndPoints';
import Method from '../../../../utils/methods';
import Loader from '../../../../Global/loader';
import ViewProducts from '../../../modals/view-products';
import {
  AllModules,
  CartonWithDozens,
  CartonWithPieces,
  Dashboard,
  Dozen,
  Piece,
} from '../../../../utils/constants';
import moment from 'moment';
import { error } from '../../../../Global/toast';
const OrdersReport = () => {
  const baseColor = getCSSVariableValue('--bs-primary');
  const navigate = useNavigate();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [categories, setCategories] = useState([]);
  const [endDate, setEndDate] = useState<any>(new Date());
  const [chartSeries, setChartSeries] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [customersData, setCustomersData] = useState<any>();
  const [ordersData, setOrderData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [showRequestedGoods, setRequestedGoods] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await fetchReports(startDate, endDate, selectedDistrict);
      await fetchCustomers(startDate, endDate, selectedDistrict);
      await fetchDistrict(1, 10);
    })();
  }, []);
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',Dashboard);
    let response = await apiService.callAPI();
    let data: any = [];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const fetchReports = async (fromDate: any, toDate: any, district: any) => {
    setLoading(true);
    let params: any = {
      fromDate: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
      toDate: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '0',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    let apiService = new APICallService(dashboard.orderReport, params,'','','','',Dashboard);
    let response = await apiService.callAPI();
    if (response) {
      if (response.ordersSaleData && response.ordersSaleData.length) {
        response.ordersSaleData = Method.populateMissingDates(
          response.ordersSaleData,
          Method.convertDateToFormat(fromDate, 'DD'),
          Method.convertDateToFormat(toDate, 'DD')
        );
        const allDatesSet = new Set([
          ...response.ordersSaleData.map((entry: any) => entry.date),
        ]);
        const allDates: any = Array.from(allDatesSet);
        setCategories(allDates);
        let temp = [
          {
            name: 'Orders',
            data: response.ordersSaleData.map((entry: any) => ({
              x: entry.totalOrders,
              y: entry.totalSales.toFixed(2),
            })),
          },
        ];
        setChartData(response.ordersSaleData);
        setChartSeries(temp);
        setOrderData(response);
      } else {
        setChartData([]);
        setChartSeries([]);
        setOrderData(null);
      }
    }
    setLoading(false);
  };
  const fetchCustomers = async (fromDate: any, toDate: any, district: any) => {
    setLoading(true);
    let params: any = {
      fromDate: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
      toDate: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '1',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    let apiService = new APICallService(dashboard.orderReport, params,'','','','',Dashboard);
    let response = await apiService.callAPI();
    if (response) {
      response.topCustomers = populateProducts(
        response.topCustomers,
        response.productsIdInfoMap
      );
      setCustomersData(response);
    }
    setLoading(false);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startMonth = moment(new Date(startDate)).format('MM');
    const endMonth = moment(new Date(endDate)).format('MM');
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
          await fetchCustomers(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
        } else {
          error(
            'Please select a date range with a minimum one-week difference'
          );
        }
      } else {
        error('Please select a date range within a single month');
      }
    }
  };
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event);
      await fetchReports(startDate, endDate, event);
      await fetchCustomers(startDate, endDate, event);
    } else {
      setSelectedDistrict(undefined);
      await fetchReports(startDate, endDate, undefined);
      await fetchCustomers(startDate, endDate, undefined);
    }
  };
  function populateProducts(topCustomers: any, productsIdInfoMap: any) {
    // Iterate through each topCustomer object
    for (const customer of topCustomers) {
      // Initialize an empty array to store products
      const products = [];
      // Iterate through the product IDs in the customer's products array
      for (const productIdInfo of customer.products) {
        // Get product info from productsIdInfoMap using the product ID
        const productId = productIdInfo._id;
        const productInfo = productsIdInfoMap[productId];
        // If product info exists, add it to the products array
        if (productInfo) {
          // Add product data to the products array
          products.push({
            _id: productId,
            title: productInfo.title,
            variantType: productInfo.variantType,
            media: productInfo.media,
            type: productInfo.type,
            quantityTypes: productIdInfo.quantityTypes,
            totalQuantities: productIdInfo.totalQuantities,
          });
        }
      }
      // Replace the customer's products array with the populated products array
      customer.products = products;
    }
    // Return the modified topCustomers data
    return topCustomers;
  }
  return (
    <>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          {products && products.length && showRequestedGoods ? (
            <ViewProducts
              show={showRequestedGoods}
              onHide={() => {
                setRequestedGoods(false);
                setProducts([]);
              }}
              products={products}
            />
          ) : (
            <></>
          )}
          <div className="card-title">
            <h2 className="fs-22 fw-bolder">Orders report</h2>
          </div>
          <div className="card-toolbar">
            <div className="min-w-md-193px">
              <CustomSelect
                backgroundColor="#ffff"
                minHeight="50px"
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
              />
            </div>
            <div className="ms-3">
              <CustomDatePicker
                className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Card.Header>
        {loading ? (
          <>
            <Card.Body className="py-0">
              <div className="d-flex flex-center min-h-140px">
                <Loader loading={loading} />
              </div>
            </Card.Body>
          </>
        ) : (
          <>
            {chartData.length > 0 ? (
              <>
                <Card.Body>
                  <Row className="g-5">
                    <Col
                      xs="auto"
                      xl={4}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {ordersData.totalOrders}{' '}
                          {ordersData.totalOrders > 1 ? 'orders' : 'order'}
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total orders
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      xl={4}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {' '}
                          {ordersData.totalCancelledOrders}{' '}
                          {ordersData.totalCancelledOrders > 1
                            ? 'orders'
                            : 'order'}
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total cancelled orders
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      xl={4}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {' '}
                          {ordersData.totalProductsSold} products
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Total products sold
                        </div>
                      </div>
                    </Col>
                    {/* <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {ordersData.totalProductsSold} plans
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Delivery plans generated
                        </div>
                      </div>
                    </Col> */}
                    <Col xs={12}>
                      <Chart
                        options={{
                          chart: {
                            fontFamily: 'inherit',
                            type: 'area' as 'area',
                            height: 275,
                            toolbar: {
                              show: false,
                            },
                            dropShadow: {
                              enabled: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'smooth',
                            show: true,
                            width: 3,
                            colors: [baseColor],
                          },
                          legend: {
                            show: false,
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: categories,
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              style: {
                                colors: '#1a1a1a',
                                fontSize: '12px',
                              },
                            },
                            tooltip: {
                              enabled: false,
                            },
                          },
                          // yaxis: {
                          //   labels: {
                          //     align: 'center',
                          //     style: {
                          //       colors: '#7c7c7c',
                          //       fontSize: '12px',
                          //     },
                          //   },
                          // },
                          yaxis: [
                            // {
                            //   seriesName: 'Total Orders',
                            //   opposite: true,
                            //   labels: {
                            //     show: false,
                            //     align: 'center',
                            //     style: {
                            //       colors: '#7c7c7c',
                            //       fontSize: '12px',
                            //     },
                            //   },
                            // },
                            {
                              seriesName: '',
                              labels: {
                                align: 'center',
                                style: {
                                  colors: '#7c7c7c',
                                  fontSize: '12px',
                                },
                                formatter: function (value) {
                                  return Method.getGeneralizedAmount(value); // Adjust locale as needed
                                },
                              },
                            },
                          ],
                          tooltip: {
                            enabled: true,
                            custom: function ({
                              series,
                              seriesIndex,
                              dataPointIndex,
                              w,
                            }) {
                              const monthData = chartData[dataPointIndex];
                              return `<div style='width:164px !important'>
                                  <div class="p-2 mb-0"><span class="fw-500 fs-16 text-dark ms-2 mb-2">Total Orders</span><div class="d-flex align-items-center ms-2 mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">${Method.getGeneralizedAmount(
                                    monthData.totalOrders
                                  )} ${
                                monthData.totalOrders > 1 ? 'order' : 'orders'
                              }</span> </div>
                                    </div>
            <div class='separator my-2'></div>
            <div class="p-2 mb-0 ms-2 mb-2"><span class="fw-500 fs-16 text-dark">Total Sales</span><div class="d-flex align-items-center mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">TSh ${Method.getGeneralizedAmount(
              monthData.totalSales
            )}</span> </div></div>
                             `;
                            },
                          },
                          colors: [baseColor],
                          grid: {
                            borderColor: '#e0e0df',
                            strokeDashArray: 5,
                            yaxis: {
                              lines: {
                                show: true,
                              },
                            },
                          },
                          fill: {
                            type: 'solid',
                            opacity: 0.05,
                          },
                          markers: {
                            size: 0,
                            colors: undefined,
                            strokeColors: '#1b74e4',
                            strokeWidth: 8,
                            strokeOpacity: 1,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            shape: 'circle',
                            radius: 0,
                            onClick: undefined,
                            onDblClick: undefined,
                            showNullDataPoints: false,
                            hover: {
                              size: undefined,
                              sizeOffset: 3,
                            },
                          },
                        }}
                        series={[
                          {
                            name: '',
                            data: chartData.map((entry: any) =>
                              entry.totalSales.toFixed(2)
                            ),
                          },
                        ]}
                        type="area"
                        height="350"
                      />
                    </Col>
                  </Row>
                  <div className="bg-white border border-r8px mt-3">
                    <Row className="g-5 align-items-center min-h-100px">
                      <Col
                        xs="auto"
                        className="h-100px d-lg-block d-none"
                      >
                        <div className="bg-light bg-efefef border-r8px border-rtb0px h-100 w-lg-100px w-50px d-flex flex-center border-end">
                          <img
                            src={IMAGES.fastDelivery}
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md="auto"
                        sm="6"
                      >
                        <div className="d-grid px-lg-5 ps-md-5">
                          <span className="fs-22 fw-600">
                            {ordersData.sameDayDelivered}{' '}
                            {ordersData.sameDayDelivered > 1
                              ? 'orders'
                              : 'order'}
                          </span>
                          <span className="fs-16 fw-500">
                            Orders delivered same day
                          </span>
                        </div>
                      </Col>
                      <Col
                        md="auto"
                        sm="6"
                      >
                        <div className="d-grid border-start-dashed border-end-dashed border-start border-end border-gray-300 px-md-5">
                          <span className="fs-22 fw-600">
                            {ordersData.nextDayDelivered}{' '}
                            {ordersData.nextDayDelivered > 1
                              ? 'orders'
                              : 'order'}
                          </span>
                          <span className="fs-16 fw-500">
                            Orders delivered next day
                          </span>
                        </div>
                      </Col>
                      <Col
                        md="auto"
                        sm="6"
                      >
                        <div className="d-grid px-lg-5">
                          <span className="fs-22 fw-600">
                            {ordersData.beyondNextDayDelivered}{' '}
                            {ordersData.beyondNextDayDelivered > 1
                              ? 'orders'
                              : 'order'}
                          </span>
                          <span className="fs-16 fw-500">
                            Orders delivered after 2 days
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row className="gy-7 mt-auto">
                    <Col md={6}>
                      <Card className="border border-r10px h-xl-100">
                        <Card.Header className="min-h-77px">
                          <h3 className="card-title align-items-start fs-22 fw-bolder flex-column">
                            Top customers
                          </h3>
                          {/* <div className="card-toolbar">
                            <Button
                              variant="link"
                              className="btn-flush fs-16 fw-bold text-primary"
                              onClick={() =>
                                navigate('/dashboard/top-customers')
                              }
                            >
                              View all
                            </Button>
                          </div> */}
                        </Card.Header>
                        <Card.Body>
                          <div className="hover-scroll-overlay-y pe-6 me-n6 h-400px">
                            {customersData.topCustomers.length &&
                              customersData.topCustomers.map((val: any) => {
                                return (
                                  <>
                                    <Row className="align-items-center g-5">
                                      <Col xs="auto">
                                        <div className="symbol symbol-50px border">
                                          <img
                                            src={val.customer.business.image}
                                            className="object-fit-contain"
                                            alt=""
                                          />
                                        </div>
                                      </Col>
                                      <Col xs>
                                        <span className="fs-16 fw-600">
                                          {val.customer.business.name} <br />
                                          <span className="fw-500">
                                            {val.totalOrders}{' '}
                                            {val.totalOrders > 1
                                              ? 'orders'
                                              : 'order'}
                                          </span>
                                        </span>
                                      </Col>
                                      <Col xs="auto">
                                        <div className="d-flex flex-column text-end fs-16 fw-600">
                                          <span className="fw-bolder">
                                            TSh{' '}
                                            {Method.getGeneralizedAmount(
                                              val.totalSales
                                            )}
                                          </span>
                                          <Button
                                            variant="link"
                                            className="btn-flush fw-600 text-decoration-underline"
                                            onClick={() => {
                                              setProducts(val.products);
                                              setRequestedGoods(true);
                                            }}
                                          >
                                            View products
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="separator my-5"></div>
                                  </>
                                );
                              })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="border border-r10px h-xl-100">
                        <Card.Header className="min-h-77px">
                          <h3 className="card-title align-items-start fs-22 fw-bolder flex-column">
                            Top products
                          </h3>
                          {/* <div className="card-toolbar">
                            <Button
                              variant="link"
                              className="btn-flush fs-16 fw-bold text-primary"
                              onClick={() =>
                                navigate('/dashboard/top-products')
                              }
                            >
                              View all
                            </Button>
                          </div> */}
                        </Card.Header>
                        <Card.Body>
                          <div className="hover-scroll-overlay-y pe-6 me-n6 h-400px">
                            {ordersData.topProducts.map((val: any) => {
                              return (
                                <>
                                  <Row className="align-items-center g-5">
                                    <Col xs="auto">
                                      <div className="symbol symbol-50px border">
                                        <img
                                          src={
                                            ordersData.productsIdInfoMap[
                                              val._id
                                            ].media[0].url
                                          }
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                    </Col>
                                    <Col xs>
                                      <span className="fs-16 fw-600">
                                        {Method.replaceSpace(
                                          ordersData.productsIdInfoMap[val._id]
                                            .title
                                        )}
                                        <br />
                                        <span className="fw-500">
                                          {val.quantityTypes
                                            .map((item: any) => {
                                              if (
                                                item.type === CartonWithDozens
                                              ) {
                                                return `${item.stockCount} C(D)`;
                                              }
                                              if (
                                                item.type === CartonWithPieces
                                              ) {
                                                return `${item.stockCount} C(P)`;
                                              }
                                              if (item.type === Dozen) {
                                                return `${item.stockCount} D`;
                                              }
                                              if (item.type === Piece) {
                                                return `${item.stockCount} P`;
                                              }
                                              return '';
                                            })
                                            .join(', ')}
                                        </span>
                                      </span>
                                    </Col>
                                  </Row>
                                  <div className="separator my-5"></div>
                                </>
                              );
                            })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body className="py-0">
                  <div className="d-flex flex-center min-h-140px">
                    <span className="fs-18 fw-medium">
                      No details available for these dates.
                    </span>
                  </div>
                </Card.Body>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};
export default OrdersReport;
